import React, { useState, useContext } from "react";
import { /*useDocument,*/ useCollection } from "react-firebase-hooks/firestore";

import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  CircularProgress
} from "@material-ui/core";

import { GenericMoreButton } from "components";
import { Chart } from "./components";

import { FirebaseContext } from "data/Firebase";
import { firebaseDataMap } from "helpers";

import {
  dayNow,
  pastThirtyDays,
  getArrayOfDates,
  getOrdersByDate,
  addTotalProfitsAndRevenuesToObject,
  getAccumulatedValuesByDate
} from "./helpers";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100%"
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  chart: {
    padding: theme.spacing(4, 2, 0, 2),
    height: 400
  },
  loading: {
    height: "100%",
    marginTop: 200,
    width: "100%",
    alignContent: "center",
    textAlign: "center"
  }
}));

const FinancialStats = ({ className, ...rest }) => {
  const classes = useStyles();

  const collectionOrders = "orders";
  const orderByTimeStamp = "timestamp";
  const firebase = useContext(FirebaseContext);

  const [query/*, setQuery*/] = useState(
    firebase
      .firestore()
      .collection(collectionOrders)
      .orderBy(orderByTimeStamp, "asc")
      .where(orderByTimeStamp, "<=", dayNow)
      .where(orderByTimeStamp, ">=", pastThirtyDays)
  );
  const [orders/*, loading, error*/] = useCollection(query, {
    snapshotListenOptions: { includeMetadataChanges: true }
  });

  let labels = { profits: [], revenues: [] };
  let data = {};

  if (orders) {
    const arrayOfDates = getArrayOfDates();
    const ordersByDate = getOrdersByDate(
      arrayOfDates,
      firebaseDataMap(orders.docs)
      );
      // console.log("test_totalArrayOfDates", arrayOfDates);
      // console.log("test_totalOrdersByDate", ordersByDate);
    const totalProfitsAndRevenues = addTotalProfitsAndRevenuesToObject(
      ordersByDate
    );
    labels = arrayOfDates.map(date => new Date(date).getDate());
    data = {
      profits: getAccumulatedValuesByDate(totalProfitsAndRevenues, 'profits'),
      revenues: getAccumulatedValuesByDate(totalProfitsAndRevenues, 'revenues'),
    };
  }

  //Left for future statistics by months
  // data = {
  //   profits: [18, 16, 5, 8, 3, 14, 14, 16, 17, 19, 18, 20],
  //   revenues: [12, 11, 4, 6, 2, 9, 9, 10, 11, 12, 13, 13]
  // };
  // const labels = [
  //   "Jan",
  //   "Feb",
  //   "Mar",
  //   "Apr",
  //   "May",
  //   "Jun",
  //   "Jul",
  //   "Aug",
  //   "Sep",
  //   "Oct",
  //   "Nov",
  //   "Dec"
  // ];

  if (!orders) {
    return (
      <div className={classes.loading}>
        {" "}
        <CircularProgress />
      </div>
    );
  }
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader action={<GenericMoreButton />} title="Financial Stats" />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Chart className={classes.chart} data={data} labels={labels} />
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

FinancialStats.propTypes = {
  className: PropTypes.string
};

export default FinancialStats;
