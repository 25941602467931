import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { slugify } from 'helpers'

import { ProductAllowReviews, Holder, Categories, ProductVisibility, ProductSEO, ProductContent } from './components';

const useStyles = makeStyles(theme => ({
  root: {},
  deliverables: {
    marginTop: theme.spacing(3)
  },
  categories: {
    marginTop: theme.spacing(3)
  }
}));

const ProductEdit = props => {
  const { productExisting, id, categories, vendors, brands, app, createNew, edit, editField, className, ...rest } = props;
  const product = productExisting
  const classes = useStyles();

  const handleProductUpdate = dic => {
    // Automatically update the product sku
      if(dic.title){
        dic.sku = slugify(dic.title)
      }
      if(dic.handle){
        dic.handle = slugify(dic.handle)
      }
      
      editField && editField(dic)
  }

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      <Grid
        item
        lg={4}
        xl={3}
        xs={12}
      >
        <ProductVisibility published={product.published} handleProductUpdate={handleProductUpdate} />
        <ProductAllowReviews allowReviews={product.allowReviews} handleProductUpdate={handleProductUpdate} />
        <Holder app={app} product={product} handleProductUpdate={handleProductUpdate} />
        <ProductSEO product={product} handleProductUpdate={handleProductUpdate} />

        {/* <Brief brief={product.description} />
        <Deliverables className={classes.deliverables} product={product} /> */}

      </Grid>
      <Grid
        item
        lg={8}
        xl={9}
        xs={12}
      >
        {/* <Holder product={product} /> */}
        <ProductContent
          product={product}
          productId={id}
          categories={categories}
          vendors={vendors}
          brands={brands}
          handleProductUpdate={handleProductUpdate}
        />
        <Categories
          className={classes.categories}
          categories={categories}
          product={product}
        />
        
      </Grid>
    </Grid>
  );
};

ProductEdit.propTypes = {
  className: PropTypes.string,
  productExisting: PropTypes.object.isRequired,
  editField: PropTypes.func.isRequired,
  categories: PropTypes.array
};

export default ProductEdit;
