import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField
} from "@material-ui/core";
import { cleanText } from "helpers";

const useStyles = makeStyles(theme => ({
  root: { marginBottom: 15 },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%"
  },
  attributes: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 150
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText
  },
  chipRed: {
    backgroundColor: theme.palette.error.light,
    margin: 2,
    color: "white"
  },
  loading: {
    height: "100%",
    marginTop: 200,
    width: "100%",
    alignContent: "center",
    textAlign: "center"
  }
}));

const PromotionContent = props => {
  const classes = useStyles();
  const {
    promotion,
    setPromotion,
    handlePromotionUpdate,
    enqueueSnackbar,
    categories,
    className,
    action,
    ...rest
  } = props;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  const theme = useTheme();

  const [loading, setLoading] = useState(false);

  const validateButtonsVisibility = () => {
    if (promotion.buttonVisible) {
      return promotion.buttonText && promotion.buttonURL ? true : false;
    } else {
      return true;
    }
  };

  const isLengthValid = (name, value) => {
    const fieldsLengthLimit80 = ["subtitle", "subtitle2"];
    const fieldsLengthLimit250 = ["paragraph"];

    if (fieldsLengthLimit80.indexOf(name) !== -1 && value.length > 80) {
      enqueueSnackbar &&
        enqueueSnackbar("No more than 80 characters are allowed", {
          variant: "error",
          autoHideDuration: 1500
        });
      return false;
    }

    if (fieldsLengthLimit250.indexOf(name) !== -1 && value.length > 250) {
      enqueueSnackbar &&
        enqueueSnackbar("No more than 250 characters are allowed", {
          variant: "error",
          autoHideDuration: 1500
        });
      return false;
    }
    return true;
  };

  const handleInputValue = name => event => {
    let value = event.target.value;
    const stringFields = [
      "title",
      "subtitle",
      "subtitle2",
      "paragraph",
      "buttonText",
      "buttonURL"
    ];

    if (isLengthValid(name, value)) {
      value = stringFields.indexOf(name) !== -1 ? cleanText(value) : null;
      setPromotion({ ...promotion, [name]: value });
    }
  };

  const handleUpdatePromotion = async () => {
    setLoading(true);
    const isUpdateValid = validateButtonsVisibility();
    if (isUpdateValid) {
      delete promotion.updatedOn;

      handlePromotionUpdate && (await handlePromotionUpdate({ ...promotion }));

      setTimeout(() => {
        setLoading(false);
      }, 800);

      return null;
    }
    setLoading(false);
    alert("Button Text and Button URL are required");
  };

  const handleCheckboxStatus = () => {
    setPromotion({
      ...promotion,
      buttonVisible: !promotion.buttonVisible
    });
  };

  const handleSelectGroups = event => {
    const categoriesKey = event.target.name;
    let value = event.target.value;
    let categoryValues = [];

    if (promotion.categories.indexOf(value) > -1) {
      categoryValues = promotion.categories.filter(c => c !== value[0]);
    } else {
      categoryValues = [...value];
    }

    setPromotion({
      ...promotion,
      [categoriesKey]: categoryValues
    });
  };

  const containsCategory = (arr, id) => {
    if (!arr) {
      return { name: "Category does not exist" };
    }
    var found = arr.find(category => {
      return category.id === id;
    });

    if (!found) return { name: "Category no longer exists!" };

    return found;
  };

  const getStyles = (name, valName, theme) => {
    return {
      color: valName.indexOf(name) === -1 ? "grey" : "white",
      backgroundColor: valName.indexOf(name) === -1 ? "white" : "#96748c"
    };
  };

  if (loading) {
    return (
      <div className={className.loading}>
        {" "}
        <CircularProgress />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <Typography variant="h4">Promotion Title:</Typography>
          <TextField
            required
            id="title-standard-required"
            label="Promotion Main Title"
            error={!promotion.title || promotion.title === "New promotion"}
            name="title"
            value={promotion.title || ""}
            onChange={handleInputValue("title")}
            className={classes.textField}
            margin="normal"
          />
          <br />
          <br />
          <Typography variant="h4">
            Promotion Subtitle:
            <Typography variant="body2">(Max 80 characters)</Typography>
          </Typography>
          <TextField
            required
            multiline
            id="subtitle-standard-required"
            label="Promotion Subtitle"
            error={
              !promotion.subtitle || promotion.subtitle === "New promotion"
            }
            name="subtitle"
            value={promotion.subtitle || ""}
            onChange={handleInputValue("subtitle")}
            className={classes.textField}
            margin="normal"
          />
          <br />
          <br />
          <Typography variant="h4">Promotion Subtitle 2:</Typography>
          <Typography variant="body2">(Max 80 characters)</Typography>
          <TextField
            required
            multiline
            id="subtitle2-standard-required"
            label="Promotion Subtitle 2"
            error={
              !promotion.subtitle2 || promotion.subtitle2 === "New promotion"
            }
            name="subtitle2"
            value={promotion.subtitle2 || ""}
            onChange={handleInputValue("subtitle2")}
            className={classes.textField}
            margin="normal"
          />
          <br />
          <br />
          <Typography variant="h4">Promotion Paragraph:</Typography>
          <Typography variant="body2">(Max 250 characters)</Typography>
          <TextField
            required
            multiline
            id="paragraph-standard-required"
            label="Promotion Paragraph"
            error={
              !promotion.paragraph || promotion.paragraph === "New promotion"
            }
            name="paragraph"
            value={promotion.paragraph || ""}
            onChange={handleInputValue("paragraph")}
            className={classes.textField}
            margin="normal"
          />
          <br />
          <br />
          <Typography variant="h4">Button Text:</Typography>
          <TextField
            required={promotion.buttonVisible ? true : false}
            multiline
            id="buttonText-standard-required"
            label="Button Text"
            error={
              promotion.buttonVisible
                ? !promotion.buttonText ||
                  promotion.buttonText === "New promotion"
                : null
            }
            name="buttonText"
            value={promotion.buttonText || ""}
            onChange={handleInputValue("buttonText")}
            className={classes.textField}
            margin="normal"
          />
          <br />
          <br />
          <Typography variant="h4">Button URL:</Typography>
          <TextField
            required={promotion.buttonVisible ? true : false}
            multiline
            id="buttonURL-standard-required"
            label="Button URL"
            error={
              promotion.buttonVisible
                ? !promotion.buttonURL ||
                  promotion.buttonURL === "New promotion"
                : null
            }
            name="buttonURL"
            value={promotion.buttonURL || ""}
            onChange={handleInputValue("buttonURL")}
            className={classes.textField}
            margin="normal"
          />
          <br />
          <br />
          <Typography variant="h4">Button Visibility:</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={promotion.buttonVisible}
                onClick={handleCheckboxStatus}
              />
            }
            label={promotion.buttonVisible ? "Activated" : "Disabled"}
          />
          <br />
          <br />
          <Typography variant="h4">Product Categories:</Typography>
          <br />
          <InputLabel htmlFor="select-multiple-chip">
            Select Product Categories
          </InputLabel>
          {promotion && (
            <Select
              multiple
              displayEmpty // Allows you to show a custom empty chip
              value={promotion.categories || []}
              onChange={handleSelectGroups}
              name="categories"
              input={<Input id="select-multiple-chip" />}
              renderValue={selected => (
                <div className={classes.chips}>
                  {selected.length > 0 && categories ? (
                    selected.map(val => {
                      const cate = containsCategory(categories, val);
                      return (
                        <Chip
                          key={cate.name}
                          label={cate.name}
                          className={classes.chip}
                        />
                      );
                    })
                  ) : (
                    <Chip
                      key={"No Selected Categories!!"}
                      label={"No Selected Categories!!"}
                      className={classes.chipRed}
                    />
                  )}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {categories &&
                categories.length > 0 &&
                categories.map(cat => (
                  <MenuItem
                    key={cat.name || "Error"}
                    value={cat.id || "Error"}
                    style={
                      promotion.categories &&
                      getStyles(cat.id, promotion.categories, theme)
                    }
                  >
                    {cat.name || "Error"}
                  </MenuItem>
                ))}
            </Select>
          )}
          {action === "update" ? (
            <Grid container justify="flex-end">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleUpdatePromotion}
              >
                Update Promotion Slide
              </Button>
            </Grid>
          ) : (
            <></>
          )}
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

PromotionContent.propTypes = {
  promotion: PropTypes.object.isRequired,
  setPromotion: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  categories: PropTypes.array,
  handlePromotionUpdate: PropTypes.func.isRequired,
  className: PropTypes.object,
  action: PropTypes.string.isRequired
};

export default PromotionContent;
