import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import SerpPreview from "react-serp-preview";
import {
  Card,
  CardContent,
  Typography,
  // Switch,
  TextField,
  Button,
  CardActions,
  Popover
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: { marginTop: 15, marginBottom: 15 },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%"
  },
  popover: {
    pointerEvents: "none"
  },
  paper: {
    padding: theme.spacing(1)
  }
}));

const ProductSEO = ({ handleProductUpdate, product, className, ...rest }) => {
  const classes = useStyles();

  const [localProduct, setLocalProduct] = useState({ ...product });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleLocalProductUpdate = name => event => {
    setLocalProduct({ ...localProduct, [name]: event.target.value });
  };

  const handleLocalProductValidation = () => {
    const seoParams = ["seoTitle", "seoDescription", "seoKeywords", "handle"];
    let validation = true;

    seoParams.forEach(param => {
      if (localProduct[param].length === 0) {
        validation = false;
        alert(`${param} is required`);
      }
    });
    return validation;
  };

  const handleUpdate = async () => {
    const isValid = handleLocalProductValidation();
    const { seoTitle, seoDescription, seoKeywords, handle } = localProduct;
    const seoParams = {
      seoTitle,
      seoDescription,
      seoKeywords,
      handle
    };

    isValid &&
      handleProductUpdate &&
      (await handleProductUpdate({ ...localProduct, ...seoParams }));
  };

  const renderPreviewPopOver = () => {
    return (
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {" "}
        <Typography>{`https://Juicefly.com/product/${product.handle}`}</Typography>
      </Popover>
    );
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Typography variant="h4">Product SEO:</Typography>
        {/* <br /> */}
        <TextField
          required
          id="seoTitle-standard-required"
          label="SEO Title Name"
          error={!localProduct.seoTitle}
          name="seoTitle"
          defaultValue={localProduct.seoTitle || ""}
          onChange={handleLocalProductUpdate("seoTitle")}
          className={classes.textField}
          margin="normal"
        />
        <TextField
          required
          id="seoDescription-standard-required"
          label="SEO Description"
          error={!localProduct.seoDescription}
          name="seoDescription"
          defaultValue={localProduct.seoDescription || ""}
          onChange={handleLocalProductUpdate("seoDescription")}
          className={classes.textField}
          margin="normal"
        />
        <TextField
          required
          id="seoKeywords-standard-required"
          label="SEO Keywords"
          error={!localProduct.seoKeywords}
          name="seoKeywords"
          defaultValue={localProduct.seoKeywords || ""}
          onChange={handleLocalProductUpdate("seoKeywords")}
          className={classes.textField}
          margin="normal"
        />
        <SerpPreview
          title={
            localProduct.seoTitle ? localProduct.seoTitle : "There is no title"
          }
          // className={classes.textField}
          style={{ width: "100%", fontFamily: "arial" }}
          metaDescription={
            localProduct.seoDescription
              ? localProduct.seoDescription
              : "There is no product description"
          }
          url={`https://Juicefly.com/product/${localProduct.handle}`}
        />
        <br />
        <br />

        <Typography variant="h4">Product Slug:</Typography>
        <Typography>{localProduct.handle}</Typography>
        {/* <TextField
          required
          fullWidth
          id="standard-required"
          label="Changing product slug might break some links! Make sure this is its intended purpose!"
          error={!localProduct.handle}
          name="handle"
          defaultValue={localProduct.handle || "No slug! Add!"}
          onChange={handleLocalProductUpdate("handle")}
          className={classes.textField}
          margin="normal"
        /> */}
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleUpdate}
          disabled={!localProduct.handle}
        >
          Update
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            window.open(
              `https://Juicefly.com/product/${localProduct.handle}`,
              "_blank"
            );
          }}
          disabled={!localProduct.handle}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          Preview
        </Button>
        {renderPreviewPopOver()}
      </CardActions>
    </Card>
  );
};

ProductSEO.propTypes = {
  published: PropTypes.bool,
  className: PropTypes.string,
  publishChange: PropTypes.func
};

export default ProductSEO;
