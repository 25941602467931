import React, { useState, useEffect } from "react";
import { query } from "../../modules/helperFunctions";
import moment from "moment";
import { Grid, Typography/*, CircularProgress*/ } from "@material-ui/core";
import { Line } from "react-chartjs-2";

const OrganicSearch = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.gapi.analytics.ready(function () {
      var data = query({
        ids: "ga:97083810",
        dimensions: "ga:date",
        metrics: "ga:pageviews,ga:sessionDuration,ga:exits",
        filters: "ga:medium==organic",
        sort: "-ga:pageviews",
        "start-date": "30daysAgo",
        "end-date": "yesterday",
      });

      Promise.all([data]).then((results) => {
        // console.log("test.OrganicSearch", results);

        var data1 = results[0].rows.map(function (row) {
          return +row[1];
        });
        var labels = results[0].rows.map(function (row) {
          return +row[0];
        });

        labels = labels.map(function (label) {
          return moment(label, "YYYYMMDD").format("Do");
        });

        var data = {
          labels: labels,
          datasets: [
            {
              label: "Sessions",
              fillColor: "rgba(151,187,205,0.5)",
              strokeColor: "rgba(151,187,205,1)",
              pointColor: "rgba(151,187,205,1)",
              pointStrokeColor: "#fff",
              data: data1,
            },
          ],
        };
        setLoading(false);
        setData(data);
      });
    });
  }, []);

  if (loading) {
    return (
      <Grid item xs={12} style={{ padding: "1.2rem" }} align="center">
        {/* <CircularProgress /> */}
      </Grid>
    );
  }
  return (
    <Grid item xs={12} style={{ padding: "1.2rem" }}>
      <Typography align="center" variant="h3">
        Organic Search
      </Typography>
      {data !== null && (
        <Line
          data={data}
          height={75}
        />
      )}
    </Grid>
  );
};

export default OrganicSearch;
