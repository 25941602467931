import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Typography,
  // Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  Input,
  Chip,
} from "@material-ui/core";
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: 15 },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  attributes: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 150,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
  chipRed: {
    backgroundColor: theme.palette.error.light,
    margin: 2,
    color: "white",
  },
}));

const PostContent = (props) => {
  const classes = useStyles();

  const {
    post,
    categories,
    vendors,
    brands,
    handlePostUpdate,
    className,
    ...rest
  } = props;

  const theme = useTheme();

  // const [updatedDescription, setDescription] = useState(null);
  // const [editorState, setEditorState] = useState(null)
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, valName, theme) {
    return {
      color: valName.indexOf(name) === -1 ? "grey" : "white",
      backgroundColor: valName.indexOf(name) === -1 ? "white" : "#96748c",
    };
  }

  function containsCategory(arr, id) {
    if (!arr) {
      return { name: "Category does not exist" };
    }
    var found = arr.find((category) => {
      return category.id === id;
    });

    if (!found) return { name: "Category no longer exists!" };

    return found;
  }

  function containsBrand(arr, id) {
    if (!arr) {
      return { name: "Brand does not exist" };
    }
    var found = arr.find((brand) => {
      return brand.id === id;
    });

    if (!found) return { name: "Brand no longer exists!" };
    return found;
  }

  // function containsVendor(arr, name) {
  //   if (!arr) {
  //     return { name: "No Vendors Detected!" };
  //   }
  //   var found = arr.find((vendor) => {
  //     return vendor.name.toLowerCase() === name.toLowerCase();
  //   });
  //   if (!found) return { name: "Vendor no longer exists!" };

  //   return found;
  // }

  //TODO: Need to handle the floats and string for each of the fields or it messes up the queries including inventory sorting
  const handleEdit = (name, value) => {
    handlePostUpdate && handlePostUpdate({ [name]: value });
  };

  const handleSelectGroups = (event) => {
    handlePostUpdate &&
      handlePostUpdate({ [event.target.name]: event.target.value });
  };

  // const handleDescriptionEdit = () => {
  //   handlePostUpdate &&
  //     handlePostUpdate({ content: [updatedDescription || ""] });
  // };

  return (
    <React.Fragment>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <Typography variant="h4">Post Title:</Typography>
          <TextField
            required
            id="title-standard-required"
            label="Post Name | WARNING this will also change slug/url of post, avoid changing once published"
            error={!post.title || post.title === "New Post"}
            name="title"
            defaultValue={post.title || ""}
            onChange={(event) =>
              handleEdit(event.target.name, event.target.value || "")
            }
            className={classes.textField}
            margin="normal"
          />
          {/* <br />
          <br /> */}
          {/* <CKEditor
            editor={ClassicEditor}
            data={post.content[0] || ""}
            name="description"
            onInit={(editor) => {
              // if (!editorState){
              //     setEditorState(editor)
              // }
              if (updatedDescription !== editor.getData()) {
                setDescription(editor.getData());
              }
              // You can store the "editor" and use when it is needed.
              // console.log('Editor is ready to use!', editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              // console.log({ event, editor, data });
              if (updatedDescription !== data) {
                setDescription(data);
              }
            }}
            onBlur={(editor) => {
              console.log("Blur.", editor);
            }}
            onFocus={(editor) => {
              console.log("Focus.", editor);
            }}
          /> */}
          {/* <br /> */}
          {/* <Button
            color={updatedDescription ? "secondary" : "primary"}
            variant="contained"
            onClick={() => {
              handleDescriptionEdit();
            }}
          >
            Update Description
          </Button> */}
          <br />
          <br />
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <Typography variant="h4">Post Categories:</Typography>
          <br />
          <InputLabel htmlFor="select-multiple-chip">
            Select post Categories
          </InputLabel>
          {post && (
            <Select
              multiple
              displayEmpty // Allows you to show a custom empty chip
              value={post.categories || []}
              onChange={handleSelectGroups}
              name="categories"
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.length > 0 && categories ? (
                    selected.map((val) => {
                      const cate = containsCategory(categories, val);
                      return (
                        <Chip
                          key={cate.name}
                          label={cate.name}
                          className={classes.chip}
                        />
                      );
                    })
                  ) : (
                    <Chip
                      key={"No Selected Categories!!"}
                      label={"No Selected Categories!!"}
                      className={classes.chipRed}
                    />
                  )}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {categories &&
                categories.length > 0 &&
                categories.map((cat) => (
                  <MenuItem
                    key={cat.name || "Error"}
                    value={cat.id || "Error"}
                    style={
                      post.categories &&
                      getStyles(cat.id, post.categories, theme)
                    }
                  >
                    {cat.name || "Error"}
                  </MenuItem>
                ))}
            </Select>
          )}
          <br />
          <br />
          <Typography variant="h4">Brands:</Typography>
          <br />
          <InputLabel htmlFor="select-multiple-chip">
            Select post Brands
          </InputLabel>
          {post && (
            <Select
              multiple
              value={post.brands || []}
              displayEmpty // Allows you to show a custom empty chip
              onChange={handleSelectGroups}
              name="brands"
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.length > 0 && brands ? (
                    selected.map((val) => {
                      const bran = containsBrand(brands, val);
                      return (
                        <Chip
                          key={bran.name}
                          label={bran.name}
                          className={classes.chip}
                        />
                      );
                    })
                  ) : (
                    <Chip
                      key={"No Selected Brands!!"}
                      label={"No Selected Brands!!"}
                      className={classes.chipRed}
                    />
                  )}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {brands &&
                brands.length > 0 &&
                brands.map((brand) => (
                  <MenuItem
                    key={brand.name || "Error"}
                    value={brand.id || "Error"}
                    style={getStyles(brand.id, post.brands || [], theme)}
                  >
                    {brand.name || "Error"}
                  </MenuItem>
                ))}
            </Select>
          )}
          <br />
          <br />
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

PostContent.propTypes = {
  className: PropTypes.string,
  post: PropTypes.object,
  handlePostUpdate: PropTypes.func.isRequired,
};

export default PostContent;
