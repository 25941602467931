import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import AddIcon from "@material-ui/icons/Add";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
// import { makeStyles } from "@material-ui/core/styles";
import TransferList from "../TransferList";

function PaperComponent(props) {
  return <Paper {...props} />;
}

// const useStyles = makeStyles(theme => ({
//   textField: {
//     marginLeft: theme.spacing(1),
//     marginRight: theme.spacing(1)
//   }
// }));

const AttributesModal = ({
  availableAttributes,
  productAttributes,
  updateAttrValueQuery,
  collectionListByProd,
  handleProductUpdate,
  app,
  productId
}) => {
  // const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title="Add attributes">
        <IconButton aria-label="Add attributes" onClick={handleOpen}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} disableBackdropClick>
        <DialogTitle disableTypography>
          <Typography align="center" gutterBottom variant="h3">
            Select or Remove Categories and Subcategories
          </Typography>
          <Typography gutterBottom variant="subtitle1">
            In order to remove an attribute, no collection should be selected
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TransferList
            attrList={availableAttributes}
            attrsInProduct={productAttributes || []}
            updateAttrValueQuery={updateAttrValueQuery}
            collectionListByProd={collectionListByProd}
            handleProductUpdate={handleProductUpdate}
            app={app}
            productId={productId}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AttributesModal;
