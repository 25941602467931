import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles/*, useTheme*/ } from '@material-ui/styles';
import {
    Grid,
    Typography,
    TextField,
    Button,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
    centerInDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
    }
}));

const UpsellDiscountValues = (props) => {
    const { discountValue, removeCondition, tab, index, updateDiscountValue } = props
    console.log('this is the value in the discount ', discountValue)
    const classes = useStyles()

    function onChangePrice(value) {
        const re = /^[0-9\b.]+$/;
    
        // if value is not blank, then test the regex
        if (value === '' || re.test(value)) {
          return value
        }
      }

      function onChangeNumber(value) {
        const re = /^[0-9]+$/;
    
        // if value is not blank, then test the regex
        if (value === '' || re.test(value)) {
          return value
        }
      }
    return (
        <Grid
            container
            spacing={3}
        >
            <Grid
                item
                md={1}
                xs={12}
                className={classes.centerInDiv}>
                <Typography
                    align="center"
                    gutterBottom
                    variant="h5"

                >
                    Buy
                </Typography>
            </Grid>
            <Grid
                item
                md={2}
                xs={12}>
                <TextField
                    fullWidth
                    name="itemNumber"
                    label='Amount'
                    type='number'
                    error={discountValue.itemNumber === ''}
                    // error={!formState.conditions || !formState.conditions[0].value}
                    onChange={(event) => {
                        const num = onChangeNumber(event.target.value)
                        updateDiscountValue('itemNumber', num !== '' ? parseFloat(num) : '' )
                        // console.log('this is the value for the item Number', event.target.value)
                    }}
                    value={discountValue.itemNumber}
                    variant="outlined"
                />
            </Grid>
            <Grid
                item
                md={1}
                xs={12}
                className={classes.centerInDiv}
            >
                <Typography
                    align="center"
                    gutterBottom
                    variant="h5"
                >
                    Get
                </Typography>
            </Grid>
            <Grid
                item
                md={2}
                xs={12}>
                <TextField
                    fullWidth
                    name="value"
                    label="Items"
                    type='number'
                    error={discountValue.value === '' || isNaN(discountValue.value)}
                    // error={!formState.conditions || !formState.conditions[0].value}
                    onChange={(event) => {
                        const num = onChangePrice(event.target.value)
                        updateDiscountValue('value', num !== '' ? parseFloat(num) : '' )
                        console.log('this is the value for the item Number', event.target.value)
                    }}
                    value={discountValue.value}
                    variant="outlined"
                />
            </Grid>
            <Grid
                item
                md={3}
                xs={12}
                className={classes.centerInDiv}
            >
                <Typography
                    align="justify"
                    // gutterBottom
                    variant="h5"
                >
                    {tab === 0 ? 'Free' : tab === 1 ? '% Off' : '$ Off'}
                </Typography>
            </Grid>
            <Grid
                item
                md={3}
                xs={12}
                className={classes.centerInDiv}
            >
                {index !== 0 && <Button
                    onClick={removeCondition}
                    variant="outlined"
                    color='secondary'
                >
                    Remove
                      </Button>}
            </Grid>
        </Grid>
    )
}

UpsellDiscountValues.propTypes = {
    className: PropTypes.string,
    data: PropTypes.object
};

export default UpsellDiscountValues;