import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";

import { FAQForm, FAQVisibility, FAQPosition } from "./components";

const useStyles = makeStyles((theme) => ({
  root: {},
  deliverables: {
    marginTop: theme.spacing(3),
  },
}));

const FAQEdit = (props) => {
  const { faq, id, app, editField, className, ...rest } = props;
  const classes = useStyles();

  const handleFAQUpdate = (dic) => {
    editField && editField(dic);
  };

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      <Grid item lg={12} xl={12} xs={12}>
        <FAQVisibility
          published={faq.status || false}
          handleFAQUpdate={handleFAQUpdate}
        />
        <FAQPosition
          position={faq.position}
          handleFAQUpdate={handleFAQUpdate}
        />
        <FAQForm faq={faq} handleFAQUpdate={handleFAQUpdate} />
      </Grid>
    </Grid>
  );
};

FAQEdit.propTypes = {
  className: PropTypes.string,
  editField: PropTypes.func.isRequired,
};

export default FAQEdit;
