import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Typography, Switch } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {marginBottom: 15}
}));

const PostAllowReviews = props => {
    const { allowReviews, handlePostUpdate, className, ...rest } = props;

    const classes = useStyles();

    const handleChecked = event => {
        handlePostUpdate && handlePostUpdate({[event.target.name]: event.target.checked})
    }

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardContent >
                <Typography variant="h4">Post Allow Reviews:</Typography>
                {/* <br /> */}
                <Switch
                    checked={allowReviews}
                    onChange={handleChecked}
                    name='allowReviews'
                    value={allowReviews}
                    title={allowReviews ? 'Allowed' : 'Not Allowed'}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            </CardContent>
        </Card>
    );
};

PostAllowReviews.propTypes = {
    allowReviews: PropTypes.bool,
    className: PropTypes.string,
    handlePostUpdate: PropTypes.func.isRequired
};

export default PostAllowReviews;
