import React, { useState, useEffect } from "react";
import loadActiveUsersModule from "../../modules/loadActiveUsersModule";
import loadViewSelector2 from "../../modules/ViewSelector2";
import {
  Grid,
  Typography,
  Card,
  CardContent,
  // CircularProgress,
} from "@material-ui/core";

const ActiveUsers = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.gapi.analytics.ready(function () {
      loadActiveUsersModule();
      loadViewSelector2();
      var viewSelector = new window.gapi.analytics.ext.ViewSelector2({
        container: "view-selector-container",
      }).execute();

      var activeUsers = new window.gapi.analytics.ext.ActiveUsers({
        container: "active-users-container",
        pollingInterval: 5,
      });

      viewSelector.on("viewChange", function (data) {
        var title = document.getElementById("view-name");

        title.textContent = data.property.name + " (" + data.view.name + ")";
        activeUsers.set(data).execute();
      });
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <Grid item xs={12} style={{ padding: "1.2rem" }} align="center">
        {/* <CircularProgress /> */}
      </Grid>
    );
  }
  return (
    <Grid item xs={12} md={6} style={{ padding: "1.2rem" }} align="center">
      <div style={{ display: "none" }} id="view-selector-container"></div>
      <div style={{ display: "none" }} id="view-name"></div>
      <Typography align="center" variant="h3">
        Active Users
      </Typography>
      <br />
      <Card style={{ marginTop: "2rem", width: "100px" }}>
        <CardContent>
          <div id="active-users-container"></div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default ActiveUsers;
