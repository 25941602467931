import * as React from "react";
import { BrowserRouter as Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';

// import firebase from "firebase/app";
// import { FirebaseAuthProvider } from "@react-firebase/auth";
// import { FirestoreProvider } from '@react-firebase/firestore';
// import { FirebaseAuthConsumer } from "@react-firebase/auth";

import { ThemeProvider } from '@material-ui/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
// import { createBrowserHistory } from 'history';
import AuthGuard from './components/AuthGuard'
// import Firebase, { FirebaseContext } from './data/Firebase'
import FirebaseProvider from 'data/Firebase'

import {
  ScrollReset,
  // GoogleAnalytics,
  // CookiesNotification
} from './components';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './assets/scss/index.scss';
import './data/Firebase'


import "react-datepicker/dist/react-datepicker.css";
import 'react-perfect-scrollbar/dist/css/styles.css';


import routes from './routes';
import theme from './theme';


// const browserHistory = createBrowserHistory();

function App(props) {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={4}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        
          <FirebaseProvider>
            <Router>
              <ScrollReset />
              {/* <GoogleAnalytics /> */}
              <AuthGuard>
              {renderRoutes(routes)}
              </AuthGuard>
            </Router>
          </FirebaseProvider>
        
      </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
