import React, { useState, useEffect } from "react";
import { query } from "../../modules/helperFunctions";
import { Grid, Typography/*, CircularProgress*/ } from "@material-ui/core";
import { Doughnut } from "react-chartjs-2";

const initialState = {
  labels: [],
  datasets: [
    {
      data: [],
      backgroundColor: [],
      hoverBackgroundColor: [],
    },
  ],
};

const UserDevice = () => {
  const [data, setData] = useState(initialState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.gapi.analytics.ready(function () {
      var data = query({
        ids: "ga:97083810",
        dimensions:
          "ga:deviceCategory",
        metrics: "ga:pageViews",
        "start-date": "30daysAgo",
        "end-date": "yesterday",
        "max-results": 5,
      });

      Promise.all([data]).then((results) => {
        // console.log("test.resultsUserDevice", results);
        var data = initialState;
        var backgroundColor = [
          "#4D5360",
          "#949FB1",
          "#D4CCC5",
          "#E2EAE9",
          "#F7464A",
        ];
        var hoverBackgroundColor = [
          "#2c406b",
          "#62666e",
          "#8c5c31",
          "#b8cfcc",
          "#b81d21",
        ];

        results[0].rows.forEach((row, i) => {
          data.labels.push(row[0]);
          data.datasets[0].data.push(+row[1]);
          data.datasets[0].backgroundColor.push(backgroundColor[i]);
          data.datasets[0].hoverBackgroundColor.push(hoverBackgroundColor[i]);
        });
        setLoading(false);
        setData(data);
      });
    });
  }, []);

  if (loading) {
    return (
      <Grid item xs={12} style={{ padding: "1.2rem" }} align="center">
        {/* <CircularProgress /> */}
      </Grid>
    );
  }
  return (
    <Grid item xs={12} md={6} style={{ padding: "1.2rem" }}>
      <Typography align="center" variant="h3">
        User Device by Session
      </Typography>
      <Doughnut data={data} height={125} />
    </Grid>
  );
};

export default UserDevice;
