import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import SerpPreview from "react-serp-preview";
import {
  Card,
  CardContent,
  Typography,
  // Switch,
  TextField,
  Button
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: { marginTop: 15, marginBottom: 15 },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%"
  }
}));

const PostSEO = props => {
  const { post, handlePostUpdate, className, ...rest } = props;

  const classes = useStyles();

  const handleEdit = event => {
    handlePostUpdate &&
      handlePostUpdate({ [event.target.name]: event.target.value });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Typography variant="h4">Post SEO:</Typography>
        {/* <br /> */}
        <TextField
          required
          id="seoTitle-standard-required"
          label="SEO Title Name"
          error={!post.seoTitle}
          name="seoTitle"
          defaultValue={post.seoTitle || ""}
          onChange={handleEdit}
          className={classes.textField}
          margin="normal"
        />
        <TextField
          required
          id="seoDescription-standard-required"
          label="SEO Description"
          error={!post.seoDescription}
          name="seoDescription"
          defaultValue={post.seoDescription || ""}
          onChange={handleEdit}
          className={classes.textField}
          margin="normal"
        />
        <TextField
          required
          id="seoKeywords-standard-required"
          label="SEO Keywords (Separated by a comma)"
          error={!post.seoKeywords}
          name="seoKeywords"
          defaultValue={post.seoKeywords || ""}
          onChange={handleEdit}
          className={classes.textField}
          margin="normal"
        />
        <SerpPreview
          title={post.seoTitle ? post.seoTitle : "There is no title"}
          // className={classes.textField}
          style={{ width: "100%", fontFamily: "arial" }}
          metaDescription={
            post.seoDescription
              ? post.seoDescription
              : "There is no post description"
          }
          url={`https://Juicefly.com/post/${post.slug}`}
        />
        <br />
        <br />

        <Typography variant="h4">Post Slug:</Typography>
        <TextField
          required
          fullWidth
          id="slug-standard-required"
          label="Changing post slug might break some links! Make sure this is its intended purpose!"
          error={!post.slug}
          name="slug"
          defaultValue={post.slug || "No slug! Add!"}
          onChange={handleEdit}
          className={classes.textField}
          margin="normal"
        />
        <br />
        <br />
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            window.open(
              `https://Juicefly.com/blog/${post.slug}`,
              "_blank"
            );
          }}
          disabled={!post.slug}
        >
          Preview
        </Button>
      </CardContent>
    </Card>
  );
};

PostSEO.propTypes = {
  published: PropTypes.bool,
  className: PropTypes.string,
  publishChange: PropTypes.func
};

export default PostSEO;
