const now = new Date();

export let dayNow = new Date();
dayNow = new Date(
  dayNow.getFullYear(),
  dayNow.getMonth(),
  dayNow.getDate(),
  0,
  0,
  0
).getTime();

export let pastThirtyDays = new Date(new Date().setDate(now.getDate() - 30));
pastThirtyDays = new Date(
  pastThirtyDays.getFullYear(),
  pastThirtyDays.getMonth(),
  pastThirtyDays.getDate(),
  0,
  0,
  0
).getTime();

const getOrderByDay = (minDate, maxDate, orders) => {
  return orders.filter(
    order => order.timestamp >= minDate && order.timestamp <= maxDate
  );
};

const estimateProductProfitLegacy = (item_price, qty, price_cost = 0) => {
  return (item_price - price_cost) * qty;
};

const estimateProductRevenueLegacy = (item_price, qty) => {
  return item_price * qty;
};

const estimateProductProfit = (price, quantity, cost = 0) => {
  return (price - cost) * quantity;
};

const estimateProductRevenue = (price, quantity) => {
  return price * quantity;
};

const estimateTotalProfitByDate = orders => {
  let profitByDate = 0;

  orders.forEach(order => {
    const products = order.products;

    products.forEach(product => {
      if (product.quantity && product.price) {
        const { quantity, price, cost = 0 } = product;
        profitByDate += estimateProductProfit(price, quantity, cost);
      } else {
        const { qty, item_price, price_cost } = product;
        profitByDate += estimateProductProfitLegacy(
          item_price,
          qty,
          price_cost
        );
      }
    });
  });
  return profitByDate;
};

const estimateTotalRevenueByDate = orders => {
  let revenueByDate = 0;

  orders.forEach(order => {
    const products = order.products;

    products.forEach(product => {
      if (product.quantity && product.price) {
        const { quantity, price } = product;
        revenueByDate += estimateProductRevenue(price, quantity);
      } else {
        const { qty, item_price } = product;
        revenueByDate += estimateProductRevenueLegacy(item_price, qty);
      }
    });
  });
  return revenueByDate;
};

export const getDayOne = () => {
  return new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0).getTime();
};

export const getArrayOfDates = () => {
  const dateArray = [];
  let date = pastThirtyDays;
  const stopDate = dayNow;

  while (date < stopDate) {
    dateArray.push(date);
    date = new Date(date).setDate(new Date(date).getDate() + 1);
  }
  return dateArray;
};

export const getOrdersByDate = (arrayOfDates, orders) => {
  let ordersByDate = { orders: {} };
  let minDate = "";
  let maxDate = "";
  let ordersInADay = [];

  for (let i = 0; i < 29; i++) {
    minDate = arrayOfDates[i];
    maxDate = arrayOfDates[i + 1];
    ordersInADay = getOrderByDay(minDate, maxDate, orders);

    ordersByDate.orders = {
      ...ordersByDate.orders,
      [minDate]: ordersInADay
    };
  }
  minDate = maxDate;
  maxDate = new Date(maxDate);
  maxDate = maxDate.setDate(maxDate.getDate() + 1);
  ordersInADay = getOrderByDay(minDate, maxDate, orders);

  ordersByDate.orders = {
    ...ordersByDate.orders,
    [minDate]: ordersInADay
  };
  return ordersByDate;
};

export const addTotalProfitsAndRevenuesToObject = ordersByDate => {
  const orders = ordersByDate.orders;
  const newObject = { ...ordersByDate, profits: {}, revenues: {} };

  for (let [key, values] of Object.entries(orders)) {
    const profit = estimateTotalProfitByDate(values);
    const revenue = estimateTotalRevenueByDate(values);

    newObject.profits = { ...newObject.profits, [key]: profit };
    newObject.revenues = { ...newObject.revenues, [key]: revenue };
  }
  return newObject;
};

export const getAccumulatedValuesByDate = (totalProfitsAndRevenues, type) => {
  const valuesByDate = Object.entries(totalProfitsAndRevenues[type]).map(
    tuple => tuple[1]
  );
  const accumulatedValuesByDate = [];

  valuesByDate.reduce(
    (acc, curr, idx) => (accumulatedValuesByDate[idx] = acc + curr),
    0
  );

  return accumulatedValuesByDate;
};
