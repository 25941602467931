import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
  },
  paper: {
    width: 200,
    height: 450,
    overflow: "auto",
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
  divider: {
    marginRight: "7px",
  },
  selectedAttribute: {
    backgroundColor: "rgba(62,101,80,0.19)",
  },
}));

const getAttrNames = (attributes) => attributes.map((at) => at.name);

const getSubAttrNames = (name, attributes) =>
  attributes.filter((at) => at.name === name);

export default function TransferList({
  attrList,
  attrsInProduct,
  updateAttrValueQuery,
  collectionListByProd,
  handleProductUpdate,
  app,
  productId,
}) {
  const classes = useStyles();

  const [attrsChecked, setAttrsChecked] = useState(
    getAttrNames(attrsInProduct)
  );
  const [collectionsChecked, setCollectionsChecked] = useState([]);
  const [currentAttribute, setCurrentAttribute] = useState("");
  const [disableFlag, setDisableFlag] = useState(false);

  const addProductIdToAttributeValue = (
    app,
    attributeName,
    value,
    productId
  ) => {
    app
      .firestore()
      .collection("product-attributes")
      .doc(attributeName)
      .collection("values")
      .doc(value)
      .update({ products: app.firestore.FieldValue.arrayUnion(productId) });
  };

  const removeProductIdToAttributeValue = (
    app,
    attributeName,
    value,
    productId
  ) => {
    app
      .firestore()
      .collection("product-attributes")
      .doc(attributeName)
      .collection("values")
      .doc(value)
      .update({ products: app.firestore.FieldValue.arrayRemove(productId) });
  };

  const handleToggleAttributes = (value) => () => {
    const currentIndex = attrsChecked.indexOf(value);
    const newChecked = [...attrsChecked];

    if (currentIndex === -1) {
      const newAttribute = { name: value, values: [] };
      newChecked.push(value);
      handleProductUpdate({ attributes: [...attrsInProduct, newAttribute] });
    } else {
      const newAttributes = attrsInProduct.filter(
        (attr) => attr.name !== value
      );

      handleProductUpdate({ attributes: [...newAttributes] });
      newChecked.splice(currentIndex, 1);
      setDisableFlag(true);
    }

    setAttrsChecked(newChecked);
  };

  const handleToggleCollection = (value) => () => {
    const currentIndex = collectionsChecked.indexOf(value);
    let newChecked = [...collectionsChecked];
    const allowedCollections = collectionListByProd.map(
      (collection) => collection.name
    );

    if (currentIndex === -1) {
      newChecked.push(value);
      addProductIdToAttributeValue(app, currentAttribute, value, productId);
    } else {
      newChecked.splice(currentIndex, 1);
      removeProductIdToAttributeValue(app, currentAttribute, value, productId)
    }
    newChecked = newChecked.filter(
      (nchkd) => allowedCollections.indexOf(nchkd) !== -1
    );
    setCollectionsChecked(newChecked);

    const newAttributes = attrsInProduct.map((at) => {
      if (at.name === currentAttribute) {
        const newAttribute = {
          name: currentAttribute,
          values: [...newChecked],
        };

        return newAttribute;
      }
      return at;
    });

    handleProductUpdate({ attributes: [...newAttributes] });
  };

  const onClickAttribute = async (value) => {
    updateAttrValueQuery && (await updateAttrValueQuery(value.name));
    let response = getSubAttrNames(value.name, attrsInProduct);

    if (response.length > 0) {
      setCollectionsChecked(response[0].values);
    }
    setCurrentAttribute(value.name);
    setDisableFlag(false);

    if (!isAttributeDisabled(value)) {
      handleToggleAttributes(value.id)();
    }
  };

  const isAttributeDisabled = (value) => {
    return (
      attrsChecked.indexOf(value.id) !== -1 &&
      !!attrsInProduct.find((attr) => attr.name === value.name) &&
      attrsInProduct.find((attr) => attr.name === value.name).values.length > 0
    );
  };

  const customAttributeList = () => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {attrList.map((value, index) => {
          const labelId = `transfer-list-item-${value.name}-label`;

          return (
            <ListItem
              key={index}
              role="listitem"
              onClick={() => onClickAttribute(value)}
              button
              className={
                value.name === currentAttribute
                  ? classes.selectedAttribute
                  : null
              }
            >
              <ListItemIcon>
                <Checkbox
                  checked={attrsChecked.indexOf(value.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                  disabled={isAttributeDisabled(value)}
                />
              </ListItemIcon>
              <Divider
                orientation="vertical"
                className={classes.divider}
                flexItem
              />
              <ListItemText id={labelId} primary={value.name} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  const customSubAttributeList = () => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {collectionListByProd.map((value, index) => {
          const labelId = `transfer-list-item-${value.name}-label`;

          return (
            <ListItem
              key={index}
              role="listitem"
              button
              disabled={disableFlag}
              onClick={handleToggleCollection(value.id)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={collectionsChecked.indexOf(value.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.name} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>{customAttributeList()}</Grid>
      <Grid item>{customSubAttributeList()} </Grid>
    </Grid>
  );
}
