// This is used to determine if a user is authenticated and
// if they are, allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React, { useState, useEffect, useContext, Fragment } from 'react'
import UserContext from "components/Session/"
import { Redirect, Route } from 'react-router-dom'
import DashboardLayout from 'layouts/Dashboard';

function PrivateRoute(props) {

  const user = useContext(UserContext)

  return (
    <Fragment>
      {user ? <DashboardLayout {...props}/> : <Redirect to='/login' />}
    </Fragment>
  )
}

export default PrivateRoute;