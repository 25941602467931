import React, { useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import googleAnalyticsStyle from "./googleAnalyticsStyle";

import authorizeUser from "./modules/authorizeUser";
import {
  Sessions,
  ActiveUsers,
  OrganicSearch,
  UserDevice,
  CTR,
  // Select
} from "./components";

const useStyles = makeStyles(googleAnalyticsStyle);

const GoogleAnalytics = () => {
  const classes = useStyles();

  useEffect(() => {
    const intializeGA = () => {
      window.gapi.analytics.ready(function () {
        authorizeUser();
      });
    };
    intializeGA();
  }, []);

  return (
    <Card className={classes.root}>
      <CardHeader title="Google Analytics" />
      <Divider />
      <CardContent className={classes.content}>
        <Grid container style={{ padding: "1.2rem" }}>
          <div
            id="embed-api-auth-container"
            style={{ cursor: "pointer" }}
          ></div>
          {/* <Select /> */}
          <Sessions />
          <OrganicSearch />
          <CTR />
          <ActiveUsers />
          <UserDevice />
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GoogleAnalytics;
