import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Typography, Switch } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {marginBottom: 15}
}));

const ProductVisibility = props => {
    const { published, handleProductUpdate, className, ...rest } = props;

    const classes = useStyles();

    const handleChecked = event => {
        handleProductUpdate && handleProductUpdate({[event.target.name]: event.target.checked})
    }

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardContent >
                <Typography variant="h4">Product Visibility:</Typography>
                {/* <br /> */}
                <Switch
                    checked={published}
                    onChange={handleChecked}
                    name='published'
                    value={published}
                    title={published ? 'Published' : 'Not Published'}
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            </CardContent>
        </Card>
    );
};

ProductVisibility.propTypes = {
    published: PropTypes.bool,
    className: PropTypes.string,
    handleProductUpdate: PropTypes.func.isRequired
};

export default ProductVisibility;
