import React, { createContext } from 'react'
import app from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import { client } from '../config'

const FirebaseContext = createContext(null)
export { FirebaseContext }


export default ({ children }) => {
  if (!app.apps.length) {
    app.initializeApp({...client})
  }
  return (
    <FirebaseContext.Provider value={app}>
      { children }
    </FirebaseContext.Provider>
  )
}

