import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Collapse,
  Divider,
  Drawer,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slider,
  TextField,
  Typography
} from '@material-ui/core';
// import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
// import AddIcon from '@material-ui/icons/Add';
import { capitalize } from 'helpers'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  flexGrow: {
    flexGrow: 1
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  tags: {
    marginTop: theme.spacing(1)
  },
  minitemLimit: {
    marginRight: theme.spacing(3)
  },
  maxLimit: {
    marginLeft: theme.spacing(3)
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

const Filter = props => {
  const { open, onClose, onFilter, resetQuery, typeoption, className, ...rest } = props;

  const classes = useStyles();

  const initialValues = {
    orderByField: '',
    itemLimit: 50,
    orderBy: '',
  };

  const [expandProject, setExpandProject] = useState(true);
  // const [expandCustomer, setExpandCustomer] = useState(false);
  const [values, setValues] = useState({ ...initialValues });
  const [orderByFieldOptions, setorderByFieldOptions] = useState(['title'])

  const handleClear = () => {
    resetQuery && resetQuery()
    setValues({ ...initialValues });
  };

  const handleFieldChange = (event, field, value) => {
    event.persist && event.persist();
    setValues(values => ({
      ...values,
      [field]: value
    }));
  };

  // const handleTagAdd = () => {
  //   setValues(values => {
  //     const newValues = { ...values };

  //     if (newValues.tag && !newValues.tags.includes(newValues.tag)) {
  //       newValues.tags = [...newValues.tags];
  //       newValues.tags.push(newValues.tag);
  //     }

  //     newValues.tag = '';

  //     return newValues;
  //   });
  // };

  // const handleTagDelete = tag => {
  //   setValues(values => {
  //     const newValues = { ...values };

  //     newValues.tags = newValues.tags.filter(t => t !== tag);

  //     return newValues;
  //   });
  // };

  const handleToggleProject = () => {
    setExpandProject(expandProject => !expandProject);
  };

  // const handleToggleCustomer = () => {
  //   setExpandCustomer(expandCustomer => !expandCustomer);
  // };

  const handleSubmit = event => {
    event.preventDefault();
    if(values.orderBy && !values.orderByField) return alert('Must selected "Sort By" if Sort type selected.')

    onFilter && onFilter(values);
  };

  if (typeoption === 'products') {
    if (orderByFieldOptions.length !== 5) { setorderByFieldOptions(['updatedOn', 'title', 'inventory', 'published', 'price', ]) }
  }

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <form
        {...rest}
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit}
      >
        <div className={classes.header}>
          <Button
            onClick={onClose}
            size="small"
          >
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <div className={classes.contentSection}>
            <div
              className={classes.contentSectionHeader}
              onClick={handleToggleProject}
            >
              <Typography variant="h5">Filter</Typography>
              {expandProject ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </div>
            <Divider />
            <Collapse in={expandProject}>
              <div className={classes.contentSectionContent}>
                <div className={classes.formGroup}>
                  <Typography
                    component="p"
                    gutterBottom
                    variant="overline"
                  >
                    Item Limit
                  </Typography>
                  <div className={classes.fieldGroup}>
                    <Slider
                      defaultValue={50}
                      // getAriaValueText={valuetext}
                      aria-labelledby="discrete-slider"
                      valueLabelDisplay="auto"
                      onChange={(event, value) =>
                        handleFieldChange(event, 'itemLimit', value)
                      }
                      step={100}
                      marks
                      min={50}
                      max={5000}
                    />
                    <Typography
                      className={classes.maxLimit}
                      variant="body1"
                    >
                      {values.itemLimit === 5000 ? 'All' : values.itemLimit}
                    </Typography>
                  </div>
                </div>
                <div className={classes.formGroup}>
                  <TextField
                    className={classes.field}
                    fullWidth
                    label="Sort By"
                    margin="dense"
                    name="orderByField"
                    error={(values.orderBy && !values.orderByField) ? true : false}
                    onChange={event =>
                      handleFieldChange(
                        event,
                        'orderByField',
                        event.target.value
                      )
                    }
                    select
                    // eslint-disable-next-line react/jsx-sort-props
                    SelectProps={{ native: true }}
                    value={values.orderByField}
                    variant="outlined"
                  >
                    <option
                      disabled
                      value=""
                    />
                    {orderByFieldOptions.map(option => (
                      <option
                        key={option}
                        value={option}
                      >
                        {capitalize(option)}
                      </option>
                    ))}
                  </TextField>
                </div>
                <div className={classes.formGroup}>
                  <Typography
                    component="p"
                    gutterBottom
                    variant="overline"
                  >
                    Sort Type
                  </Typography>
                  <div className={classes.fieldGroup}>
                    <RadioGroup
                      className={classes.radioGroup}
                      name="orderBy"
                      onChange={event =>
                        handleFieldChange(
                          event,
                          'orderBy',
                          event.target.value
                        )
                      }
                      value={values.orderBy}
                    >
                      <FormControlLabel
                        control={<Radio color="primary" />}
                        label="Desc"
                        value="desc"
                      />
                      <FormControlLabel
                        control={<Radio color="primary" />}
                        label="Asc (default)"
                        value="asc"
                      />
                    </RadioGroup>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
          {/* <div className={classes.contentSection}>
            <div
              className={classes.contentSectionHeader}
              onClick={handleToggleCustomer}
            >
              <Typography variant="h5">Customer</Typography>
              {expandCustomer ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </div>
            <Divider />
            <Collapse in={expandCustomer}>
              <div className={classes.contentSectionContent}>
                <div className={classes.contentSectionContent}>
                  <div className={classes.formGroup}>
                    <Typography
                      component="p"
                      gutterBottom
                      variant="overline"
                    >
                      Sort type
                  </Typography>
                    <div className={classes.fieldGroup}>
                      <RadioGroup
                        className={classes.radioGroup}
                        name="orderBy"
                        onChange={event =>
                          handleFieldChange(
                            event,
                            'orderBy',
                            event.target.value
                          )
                        }
                        value={values.orderBy}
                      >
                        <FormControlLabel
                          control={<Radio color="primary" />}
                          label="Ended"
                          value="ended"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" />}
                          label="On-Going"
                          value="onGoing"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" />}
                          label="In Review"
                          value="inReview"
                        />
                        <FormControlLabel
                          control={<Radio color="primary" />}
                          label="Competed"
                          value="completed"
                        />
                      </RadioGroup>
                    </div>
                  </div>

                  <div className={classes.formGroup}>
                    <ToggleButtonGroup
                      exclusive
                      onChange={(event, value) =>
                        value && handleFieldChange(event, 'customerType', value)
                      }
                      size="small"
                      value={values.customerType}
                      variant="outlined"
                    >
                      <ToggleButton
                        color="primary"
                        value="projectOwner"
                      >
                        Project owner
                      </ToggleButton>
                      <ToggleButton value="freelancer">Freelancer</ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                  <div className={classes.formGroup}>
                    <TextField
                      className={classes.field}
                      fullWidth
                      label="Email address"
                      margin="dense"
                      name="customerEmail"
                      onChange={event =>
                        handleFieldChange(
                          event,
                          'customerEmail',
                          event.target.value
                        )
                      }
                      value={values.customerEmail}
                      variant="outlined"
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <TextField
                      className={classes.field}
                      fullWidth
                      label="Phone number"
                      margin="dense"
                      name="customerPhone"
                      onChange={event =>
                        handleFieldChange(
                          event,
                          'customerPhone',
                          event.target.value
                        )
                      }
                      value={values.customerPhone}
                      variant="outlined"
                    />
                  </div>
                  <div className={classes.formGroup}>
                    <TextField
                      className={classes.field}
                      fullWidth
                      label="Age"
                      margin="dense"
                      name="customerAge"
                      onChange={event =>
                        handleFieldChange(
                          event,
                          'customerAge',
                          event.target.value
                        )
                      }
                      select
                      // eslint-disable-next-line react/jsx-sort-props
                      SelectProps={{ native: true }}
                      value={values.customerAge}
                      variant="outlined"
                    >
                      <option
                        disabled
                        value=""
                      />
                      {customerAgeOption.map(option => (
                        <option
                          key={option}
                          value={option}
                        >
                          {option}
                        </option>
                      ))}
                    </TextField>
                  </div>
                </div>
              </div>
            </Collapse>
          </div> */}
        </div>
        <div className={classes.actions}>
          <Button
            fullWidth
            onClick={handleClear}
            variant="contained"
          >
            <DeleteIcon className={classes.buttonIcon} />
            Clear
          </Button>
          <Button
            color="primary"
            fullWidth
            type="submit"
            variant="contained"
          >
            Apply filters
          </Button>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  typeoption: PropTypes.string,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
  open: PropTypes.bool.isRequired
};

Filter.defaultProps = {
  typeoption: ''
};

export default Filter;
