import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Icon,
  Checkbox,
  TableHead,
} from "@material-ui/core";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

import roles from "./roles";

const useStyles = makeStyles((theme) => ({
  root: {},
  sendButton: {
    marginTop: theme.spacing(2),
  },
  table: {
    // marginTop: theme.spacing(2),
  },
  content: {
    paddingTop: "0",
  },
  header: {
    height: '3rem',
  },
  cell: {
    padding: theme.spacing(1),
  },
  permissionsRow: {
    display: "flex",
    alignItems: "center",
    lineHeight: "1.2rem",
    "& span": {
      fontSize: ".8rem",
    },
  },
}));

const RoleEdit = (props) => {
  const {
    className,
    userSessionID,
    customerID,
    customerRole,
    updateField,
    ...rest
  } = props;
  const classes = useStyles();

  const handleUpdateDocument = (roleId) => {
    updateField && updateField({ role: roleId });
  };

  const handleSelectRole = (roleId) => (event) => {
    handleUpdateDocument(roleId);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="Update Role" />
      <Divider />
      <CardContent className={classes.content}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.header}>
              <TableCell className={classes.cell} align="center">
                Role
              </TableCell>
              <TableCell className={classes.cell} align="center">
                Permissions
              </TableCell>
              <TableCell className={classes.cell} align="center">
                Selected
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {roles.map((role) => (
              <TableRow hover key={role.id}>
                <TableCell className={classes.cell} align="center">
                  <Typography variant="subtitle2">{role.roleName}</Typography>
                  <SupervisorAccountIcon />
                </TableCell>
                <TableCell className={classes.cell}>
                  {role.privileges.map((privilege, index) => {
                    return (
                      <div key={index} className={classes.permissionsRow}>
                        {privilege.status ? (
                          <Icon color="primary">check_circle_outline</Icon>
                        ) : (
                          <Icon style={{ color: "red" }}>not_interested</Icon>
                        )}
                        <span>{privilege.label}</span>
                      </div>
                    );
                  })}
                </TableCell>
                <TableCell padding="checkbox" align="center">
                  <Checkbox
                    disabled={customerID === userSessionID}
                    key={role.id}
                    checked={customerRole === role.id}
                    onClick={handleSelectRole(role.id)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

RoleEdit.propTypes = {
  className: PropTypes.string,
  userSessionID: PropTypes.string.isRequired,
  customerID: PropTypes.string.isRequired,
  updateField: PropTypes.func.isRequired,
};

export default RoleEdit;
