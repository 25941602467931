import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
// import { slugify } from "helpers";

import { Holder, PromotionContent } from "./components";

const useStyles = makeStyles(theme => ({
  root: {}
}));

const PromotionEdit = props => {
  const {
    app,
    editField,
    promotion,
    setPromotion,
    enqueueSnackbar,
    categories,
    className,
    action,
    ...rest
  } = props;
  const classes = useStyles();

  const [localPromotion, setLocalPromotion] = useState({ ...promotion });
  const handlePromotionUpdate = dic => {
    editField && editField(dic);
  };

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      <Grid item lg={4} xl={3} xs={12}>
        <Holder
          app={app}
          promotion={action === "create" ? promotion : localPromotion}
          setPromotion={action === "create" ? setPromotion : setLocalPromotion}
          handlePromotionUpdate={handlePromotionUpdate}
        />
      </Grid>
      <Grid item lg={8} xl={9} xs={12}>
        <PromotionContent
          promotion={action === "create" ? promotion : localPromotion}
          setPromotion={action === "create" ? setPromotion : setLocalPromotion}
          enqueueSnackbar={enqueueSnackbar}
          categories={categories}
          handlePromotionUpdate={handlePromotionUpdate}
          className={classes}
          action={action}
        />
      </Grid>
    </Grid>
  );
};

PromotionEdit.propTypes = {
  app: PropTypes.object.isRequired,
  editField: PropTypes.func.isRequired,
  promotion: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  categories: PropTypes.array,
  setPromotion: PropTypes.func,
  setLocalPromotion: PropTypes.func,
  className: PropTypes.object,
  action: PropTypes.string.isRequired,
  id: PropTypes.string
};

export default PromotionEdit;
