import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  CardMedia,
  Button
} from "@material-ui/core";
import FileUploader from "react-firebase-file-uploader";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    textAlign: "center"
  },
  header: {
    paddingBottom: 0
  },
  media: {
    height: "auto",
    paddingTop: "100%" // 16:9
  },
  content: {
    paddingTop: 0,
    textAlign: "center"
  },
  listItem: {
    padding: theme.spacing(2, 0),
    justifyContent: "space-between"
  },
  image: { maxWidth: "100%" }
}));

const Holder = props => {
  const {
    promotion,
    app,
    handlePromotionUpdate,
    setPromotion,
    className,
    ...rest
  } = props;
  const [isUploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(null);
  const [localImageURL, setlocalImageURL] = useState("");

  const classes = useStyles();

  function handleUploadStart() {
    setUploading(true);
    setProgress(0);
  }

  function handleProgress(progress) {
    setProgress(progress);
  }
  function handleUploadError(error) {
    setUploading(false);
    console.error(error);
  }

  function handleUploadSuccess(filename) {
    setUploading(false);
    setProgress(100);
    app
      .storage()
      .ref("promotion")
      .child(filename)
      .getDownloadURL()
      .then(
        url => setlocalImageURL(url)
        // setPromotion({ ...promotion, ["imageURL"]: url })
      );
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      {promotion && promotion.imageURL ? (
        <CardMedia
          className={classes.media}
          image={promotion.imageURL}
          title={promotion.title || "No promotion Name set!"}
        />
      ) : (
        <Typography
          component="h1"
          gutterBottom
          variant="h3"
          style={{ color: "red" }}
        >
          No image has been selected!
        </Typography>
      )}
      <CardContent className={classes.content}>
        <div>
          <FileUploader
            accept="image/*"
            randomizeFilename
            storageRef={app.storage().ref("promotion")}
            onUploadStart={handleUploadStart}
            onUploadError={handleUploadError}
            onUploadSuccess={handleUploadSuccess}
            onProgress={handleProgress}
          />
        </div>

        <br />
        <div>
          {isUploading && <p>Progress: {progress}</p>}
          {localImageURL && (
            <img className={classes.image} src={localImageURL}  alt="uploaded-url"/>
          )}
        </div>
        <br />
        <br />
      </CardContent>
      <CardActions>
        {localImageURL && (
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              setPromotion({ ...promotion, imageURL: localImageURL });
              // handlePromotionUpdate({ localImageURL });
            }}
          >
            Update Image
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

Holder.propTypes = {
  app: PropTypes.object.isRequired,
  promotion: PropTypes.object.isRequired,
  setPromotion: PropTypes.func,
  handlePromotionUpdate: PropTypes.func.isRequired,
  className: PropTypes.object,
};

export default Holder;
