import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Typography,
  CardActions,
  CardMedia,
  Button,

} from '@material-ui/core';
import FileUploader from "react-firebase-file-uploader";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 10,
    textAlign: 'center'
  },
  header: {
    paddingBottom: 0
  },
  media: {
    height: 'auto',
    // maxHeight: 300,
    paddingTop: '100%', // 16:9
  },
  content: {
    paddingTop: 0,
    textAlign: 'center'
  },
  listItem: {
    padding: theme.spacing(2, 0),
    justifyContent: 'space-between'
  },
  image: { maxWidth: '100%' }
}));

const Holder = props => {
  const { hero, app, handleheroUpdate, className, ...rest } = props;
  const [isUploading, setUploading] = useState(false)
  const [progress, setProgress] = useState(null)
  // const [avatar, setAvatar] = useState(null)
  const [avatarURL, setAvatarURL] = useState(null)
  const [heroState, setHero] = useState({})
  const [updatedDescription/*, setDescription*/] = useState(false)

  const classes = useStyles();

  function setHeroField(field, value) {
    const hero = heroState
    hero[field] = value
    setHero({ ...hero })
  }

  function handleUploadStart() { setUploading(true); setProgress(0) }

  function handleProgress(progress) { setProgress(progress) }
  function handleUploadError(error) {
    // this.setState({ isUploading: false });
    setUploading(false);
    console.error(error);
  };

  function handleUploadSuccess(filename) {
    // setAvatar(filename);
    setUploading(false);
    setProgress(100);
    app
      .storage()
      .ref("hero")
      .child(filename)
      .getDownloadURL()
      .then(url => setAvatarURL(url));
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      {(hero && hero.imageURL)
        ? <CardMedia
          className={classes.media}
          image={hero.imageURL}
          title={hero.title || 'No hero Name set!'}
        />
        : <Typography
          component="h1"
          gutterBottom
          variant="h3"
          style={{ color: 'red' }}
        >No image has been selected!
        </Typography>}
      <CardContent className={classes.content}>
        <div>
          <FileUploader
            accept="image/*"
            randomizeFilename
            storageRef={app.storage().ref("hero")}
            onUploadStart={handleUploadStart}
            onUploadError={handleUploadError}
            onUploadSuccess={handleUploadSuccess}
            onProgress={handleProgress}
          />

        </div>

        <br />
        <div >
          {isUploading && <p>Progress: {progress}</p>}
          {avatarURL && <img className={classes.image} src={avatarURL} alt="uploaded-url-file"/>}
        </div>
        <br />
        <br />
      </CardContent>
      <CardActions>
        {avatarURL && <Button
          color={updatedDescription ? 'success' : 'secondary'}
          variant="contained"
          onClick={() => {
            setHeroField('image', avatarURL)
            handleheroUpdate({ image: avatarURL })
          }} >
          Update Image
          </Button>}
      </CardActions>
    </Card>
  );
};

Holder.propTypes = {
  className: PropTypes.string,
  hero: PropTypes.object.isRequired
};

export default Holder;
