import React, { useState } from "react";
import PropTypes from "prop-types";
import { slugify } from "helpers";

import styled from "./styled";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";

const useStyles = makeStyles(styled);

const FAQForm = (props) => {
  const { faq, handleFAQUpdate, ...rest } = props;
  const [value, setValue] = useState({
    title: faq.title,
    question: faq.question,
  });
  const classes = useStyles();

  const handleChangeValue = (event) => {
    const attribute = event.target.name;
    const fieldValue = slugify(event.target.value);

    setValue({ ...value, [attribute]: fieldValue });
  };

  return (
    <Card {...rest} className={classes.root}>
      <CardContent>
        <Typography variant="h4">FAQ Content:</Typography>
        <TextField
          required
          id="faqTitle-standard-required"
          label="FAQ Title"
          error={!faq.title}
          name="title"
          value={value.title || ""}
          onChange={handleChangeValue}
          className={classes.textField}
          margin="normal"
        />

        <TextField
          required
          id="faqQuestion-standard-required"
          label="FAQ Question"
          error={!faq.question}
          name="question"
          value={value.question || ""}
          onChange={handleChangeValue}
          className={classes.textField}
          margin="normal"
        />
        <br />
        <br />
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            handleFAQUpdate(value);
          }}
          disabled={
            faq.title === value.title && faq.question === value.question
          }
        >
          Update Content
        </Button>
      </CardContent>
    </Card>
  );
};

FAQForm.propTypes = {
  faq: PropTypes.object.isRequired,
  handleFAQUpdate: PropTypes.func.isRequired,
};

export default FAQForm;
