import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, Typography } from '@material-ui/core';
import EmailEditor from 'react-email-editor'

const useStyles = makeStyles(theme => ({
    root: {},
    deliverables: {
        marginTop: theme.spacing(3)
    },
    categories: {
        marginTop: theme.spacing(3)
    }
}));

const EmailBuilder = props => {
    const { className, ...rest } = props;
    const classes = useStyles();
    const [editor, setEditor] = useState(null)
    const [exported, setExported] = useState('')

    function exportHtml() {
        editor && editor.exportHtml(data => {
            const { html } = data
            // Getting only the body of the code
            // var bodyHtml = /<body.*?>([\s\S]*)<\/body>/.exec(data)[1];

            setExported(html)
            
            //This part is legacy and was meant to be used in actual site, not used anymore as part of Dante2 Integration.
            //   var bEnd, bStart;
            //   bStart = html.indexOf("<body");
            //   bEnd = html.indexOf("</body");
            //   var body = html.slice(bStart, bEnd);
            //   const finalHTML = `<div${body}</div>`
            //   setExported(finalHTML)
        })
    }

    return (
        <Grid
            {...rest}
            className={clsx(classes.root, className)}
            container
            spacing={3}
        >
            <Grid
                item
                lg={12}
                xl={12}
                xs={12}
            >
                <EmailEditor
                    // onLoad={() => registerEditorImage()} //This is used to register upload to Juicefly storage
                    ref={editor => setEditor(editor)}
                />
            </Grid>
            <Grid
                item
                lg={4}
                xl={3}
                xs={12}
            >
                <Button color='secondary'
                    onClick={() => exportHtml()}>
                    Export HTML
        </Button>
                {/* <Holder product={product} /> */}


            </Grid>
            <Grid
                item
                md={12}
            >
                <Typography>
                    {exported.toString()}
                </Typography>
            </Grid>
        </Grid>
    );
};

EmailBuilder.propTypes = {
    className: PropTypes.string
};

export default EmailBuilder;