import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
// import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  Select,
  MenuItem,
  TextField,
  Input
} from '@material-ui/core';
// import { Label } from 'components';

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 200,
    maxWidth: '100%'
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '100%',
  },
}));

const ProductSearchList = props => {
  const { products, className, productSearch, handleSearch, updateField, calculateTotals, ...rest } = props;

  const classes = useStyles();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: '80%',
      },
    },
  };

  const handleSearchQuery = (event) => {
    const value = event.target.value
    handleSearch && handleSearch({ [event.target.name]: value ? value.toLowerCase() : '' })
  }

  function removeProduct(id) {
    let tempArr = products.filter((prod) => {
      return prod.id !== id;
    });

    updateField({ products: tempArr || [] })

  }

  const handleSetProduct = event => {
    const product = event.target.value
    if (parseInt(product.inventory) < 1) {
      alert('Product not in stock!')
      return
    }
    if (!product.price) {
      alert('Product has no price set!')
      return
    }
    const currentProducts = products
    const dic = {
      sku: product.sku,
      title: product.title,
      categories: product.categories || [],
      mainImage: product.mainImage,
      line_id: currentProducts.length || 0,
      price: product.price,
      qty: 1,
      id: product.id,
      inventory: product.inventory
    }

    currentProducts.push(dic)
    updateField({ products: currentProducts || []})
    calculateTotals && calculateTotals()
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title={<div>
        <div>Products</div>
        <div>
          {<TextField
            required
            id="product-standard-required"
            label="New Product Search"
            name="product"
            defaultValue={''}
            onChange={handleSearchQuery}
            className={classes.textField}
            margin="normal"
          />}

          {(productSearch && productSearch.length > 0) && <Select
            value={productSearch || []}
            onChange={handleSetProduct}
            name='product'
            input={<Input id="select-multiple-chip" />}
            renderValue={selected => (
              <div className={classes.chips}>
                Select a Product from list
              </div>
            )}
            MenuProps={MenuProps}
          >
            {(productSearch && productSearch.length > 0) && productSearch.map(prod => (
              <MenuItem key={prod.id || 'Error'} value={prod || 'Error'}
              >
                {`Add: ${prod.title} ** ${prod.sku}: $${prod.price ? prod.price.toFixed(2) : 'NO PRICE!!'} ** Stock: ${prod.inventory}`}
              </MenuItem>
            ))}
          </Select>}

        </div>
      </div>} />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(products && products.length > 0) && products.map((item, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <Avatar
                        className={classes.avatar}
                        src={item.mainImage}
                      />
                    </TableCell>
                    <TableCell>
                      {/* <Link
                  href={`https://www.Juicefly.com/product/${item.sku}`}
                > */}
                      {item.title}
                      {/* </Link> */}
                    </TableCell>
                    <TableCell>{item.sku}</TableCell>
                    <TableCell>
                      <Button color="primary" onClick={() => removeProduct(item.id)}>Remove</Button>
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

ProductSearchList.propTypes = {
  className: PropTypes.string,
  products: PropTypes.array
};

export default ProductSearchList;
