import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { slugify } from "helpers";

import {
  PostAllowReviews,
  Holder,
  Categories,
  PostVisibility,
  PostSEO,
  PostContent,
} from "./components";

const useStyles = makeStyles((theme) => ({
  root: {},
  deliverables: {
    marginTop: theme.spacing(3),
  },
  categories: {
    marginTop: theme.spacing(3),
  },
}));

const PostEdit = (props) => {
  const {
    postExisting,
    categories,
    vendors,
    brands,
    app,
    createNew,
    edit,
    editField,
    className,
    ...rest
  } = props;
  const post = postExisting;
  const classes = useStyles();

  const handlePostUpdate = (dic) => {
    // Automatically update the post sku
    if (dic.title) {
      dic.sku = slugify(dic.title);
    }
    if (dic.slug) {
      // Slugify the handle url
      dic.slug = slugify(dic.slug);
    }
    editField && editField(dic);
  };

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      <Grid item lg={4} xl={3} xs={12}>
        <PostVisibility
          published={post.status}
          handlePostUpdate={handlePostUpdate}
        />
        <PostAllowReviews
          allowReviews={post.allowReviews}
          handlePostUpdate={handlePostUpdate}
        />
        <Holder app={app} post={post} handlePostUpdate={handlePostUpdate} />
        <PostSEO post={post} handlePostUpdate={handlePostUpdate} />
      </Grid>
      <Grid item lg={8} xl={9} xs={12}>
        {/* <Holder post={post} /> */}
        <PostContent
          post={post}
          categories={categories}
          vendors={vendors}
          brands={brands}
          handlePostUpdate={handlePostUpdate}
        />

        <Categories
          className={classes.categories}
          categories={categories}
          post={post}
        />
      </Grid>
    </Grid>
  );
};

PostEdit.propTypes = {
  className: PropTypes.string,
  // post: PropTypes.object.isRequired,
  editField: PropTypes.func.isRequired,
  categories: PropTypes.array,
};

export default PostEdit;
