import React, { useState, useContext/*, useEffect*/ } from "react";
import PropTypes/*, { string }*/ from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Typography, Button } from "@material-ui/core";
import Dante from "Dante2";
import { convertToRaw } from "draft-js";
import customHTML2Content from 'Dante2/package/lib/utils/html2content'
import { ImageBlockConfig } from "Dante2/package/lib/components/blocks/image.js";

import {CodeBlockConfig} from 'Dante2/package/lib/components/blocks/code.js';
import {EmbedBlockConfig} from 'Dante2/package/lib/components/blocks/embed.js';
import {VideoBlockConfig} from 'Dante2/package/lib/components/blocks/video.js';
import {PlaceholderBlockConfig} from 'Dante2/package/lib/components/blocks/placeholder.js';
// import {DividerBlockConfig} from "Dante2/package/lib/components/blocks/divider.js";
import {VideoRecorderBlockConfig} from "Dante2/package/lib/components/blocks/videoRecorder"; //This is a folder with index.js

import { FirebaseContext } from "data/Firebase";

import wordsCount from "words-count";

const useStyles = makeStyles((theme) => ({
  root: {minHeight: '100vh'},
  root2: {minHeight: '20vh'},
  wordCountContainer: {
    backgroundColor: '#F4F6F8',
    display: 'flex',
    alignItems: 'center',
    padding: '1rem'
  }
}));

const initializeContent = (contentString) => {
  try {
    // Check to see if the content is still html and converting that to a raw content state
    if (contentString.includes('<p>') || contentString.includes('<div>') || contentString.includes('<li>')) {
      return convertToRaw(customHTML2Content(contentString))
    }
    return JSON.parse(contentString);
  } catch (err) {

    console.log("String could not be parsed or converted", err);
    return '' // Still return an empty value
  }
};

const Editor = (props) => {
  const { text, type, collectionPath, editField, className, docLabel, ...rest } = props;
  console.log('this is the classename', className);

  const classes = useStyles();
  const app = useContext(FirebaseContext);

  const [content/*, setContent*/] = useState(initializeContent(text));
  const [JSONContent, setJSONContent] = useState("");
  const [wordCounter, setWordCounter] = useState(0);
  const [charCounter, setCharCounter] = useState(0);

  // Handle based on type
  const handleDocUpdate = () => {

    const updateDic = typeDic(JSONContent)
    // Check for function and type of page for update
    editField && updateDic && editField(updateDic);
  };

const typeDic = (value) => {
  if(!type) return false
  switch (type) {
    case 'product':
      return { description: value || "" }
    case 'post':
      return { content: [value || ""] }
    case 'page':
      return { html: value || "" }
    case 'faqs':
      return { answer: value || "" }
    default:
      return false
  }
}

const getNumberOfWords = jsonData => {
  let totalWords = 0;

  if(jsonData !== undefined) {
    jsonData.blocks.forEach(obj => {
      if(obj.text.length > 0) {
        let wordsInLine = wordsCount(obj.text)

        totalWords = totalWords + wordsInLine
      }
    })
  }

  return totalWords
}

const getNumberOfCharacters = jsonData => {
  let totalCharacters = 0;

  if(jsonData !== undefined) {
    jsonData.blocks.forEach(obj => {
      if(obj.text.length > 0) {
        let charsInLine = obj.text.replace(' ', '').trim()

        totalCharacters = totalCharacters + charsInLine.length
      }
    })
  }

  return totalCharacters
}

const parseContentToJSON = (contentState) => JSON.stringify(convertToRaw(contentState))

// newConent is used along with danteProps and the other way to render it
const updateContent = (editorContext, newContent) => {
  const contentState = editorContext.save.editorState().getCurrentContent();
  const tmpJSON = convertToRaw(contentState);

  setWordCounter(getNumberOfWords(tmpJSON))
  setCharCounter(getNumberOfCharacters(tmpJSON))
  setJSONContent(parseContentToJSON(contentState));
  // setContent(newContent);
};

// let danteProps = {
//   data_storage: {
//     save_handler: updateContent,
//   }
// };

const uploadImageHandler = async (file, imageBlock) => {
  if(!file || !file.name) return;
  const promise = await app
    .storage()
    .ref(collectionPath)
    .child(`${file.name}`)
    .put(file);

  // console.log("test.data", promise);

  imageBlock.updateProgressBar({
    lengthComputable: true,
    loaded: promise.bytesTransferred,
    total: promise.totalBytes,
  });

  const imageURL = await app
    .storage()
    .ref(collectionPath)
    .child(promise.metadata.name)
    .getDownloadURL()
    .then((url) => url);
  console.log("test.imageURL", imageURL);

  try {
    imageBlock.uploadCompleted(imageURL);
    alert("File uploaded");
  } catch (err) {
    imageBlock.uploadFailed();
    console.log("File upload failed", err);
  }
};

const widgets = [
  ImageBlockConfig({
    options: {
      upload_handler: uploadImageHandler,
    },
  }),
  CodeBlockConfig(),
  EmbedBlockConfig(),
  VideoBlockConfig(),
  PlaceholderBlockConfig(),
  // DividerBlockConfig(), // TODO: This one has an issue
  VideoRecorderBlockConfig()
];

return (
  <>
    {/* <Typography variant="h4">{`${docLabel || ''} Description:`}</Typography>
    <br /> */}
    <Card {...rest} className={clsx( className ? className.editor : classes.root)}>
      
      <CardContent>
        {/* <Dante {...danteProps} /> */}
        {/* content={content} si only working to set initial state */}
        <Dante content={content}
          body_placeholder={'Juicefly create zone'}
          
          config={{ debug: false, read_only: false }}
          onChange={updateContent}
          widgets={widgets} />
      </CardContent>
    </Card>
    <br/>
    {JSONContent && <Card {...rest} className={clsx(classes.wordCountContainer, className)}>
      <Typography>
        {`${wordCounter} words, ${charCounter} characters `}
      </Typography>
    </Card>}
    <br />
    <Button
      color="secondary"
      variant="contained"
      onClick={handleDocUpdate}
    >
      Update Description
      </Button>
  </>
);
};

Editor.propTypes = {
  className: PropTypes.object,
  text: PropTypes.string.isRequired,
  editField: PropTypes.func.isRequired,
};

export default Editor;
