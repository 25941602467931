import React from "react";

// import ThemeContext from "./ThemeContext";
import { FirestoreMutation } from "@react-firebase/firestore";
import { Button, Icon } from "tabler-react";

function updateDocumentButton(query, data) {
  const { path, type, text } = query;
  return (
    <FirestoreMutation type={type} path={path}>
      {({ runMutation }) => {
        return (
          <div>
            <Button
              size="sm"
              color="secondary"
              onClick={() => {
                console.log(
                  "This is the data that is going to be update",
                  data
                );
                runMutation({
                  data
                }).then(res => {
                  // Alert('Saved!')
                  console.log("Ran mutation ", res);
                });
              }}
            >
              {text}
            </Button>
          </div>
        );
      }}
    </FirestoreMutation>
  );
}

function updateDocument(firebase, collection, doc, data) {
  const firestore = firebase.firestore();
  // console.log('the firestore!', firestore)
  return firestore
    .collection(collection)
    .doc(doc)
    .update(data)
    .then(() => true)
    .catch(err => {
      console.log("there was an error updating", err);
      return false;
    });
}

async function createSubcollectionDocument(firebase, collection, doc, data) {
  const firestore = firebase.firestore();

  const subCollectionID = await firestore
    .collection(collection)
    .doc(doc)
    .collection("values")
    .add(data)
    .then(ref => ref.id);

  return subCollectionID;
}

async function createAttrSubcollDocDefID(firebase, collection, doc, id, data) {
  const firestore = firebase.firestore();

  const subCollectionID = await firestore
  .collection(collection)
  .doc(doc)
  .collection("values")
  .doc(id)
  .set(data)
  .then(ref => id);
  return subCollectionID;
}

function updateSubcollectionDocument(firebase, collection, doc, doc2, data) {
  const firestore = firebase.firestore();

  return firestore
    .collection(collection)
    .doc(doc)
    .collection("values")
    .doc(doc2)
    .update(data)
    .then(() => {
      console.log("Successfully updated");
      return true;
    })
    .catch(err => {
      console.log("there was an error updating", err);
      return false;
    });
}

async function delSubcollectionDocument(
  firebase,
  collection,
  docId,
  doc2Id,
) {
  const firestore = firebase.firestore();
  return await firestore
    .collection(collection)
    .doc(docId)
    .collection("values")
    .doc(doc2Id)
    .delete()
    .then(() => {
      console.log("Successfully removed");
      return true;
    })
    .catch(err => {
      console.log("there was an error deleting:", err);
      return false;
    });
}

async function createDocument(firebase, collection, data) {
  const firestore = firebase.firestore();
  const docID = await firestore
    .collection(collection)
    .add(data)
    .then(ref => {
      return ref.id;
    });

  return docID;
}

async function createDocDefnID(firebase, collection, id, data) {
  if(!id) return false;

  const firestore = firebase.firestore();
  const docID = await firestore
    .collection(collection)
    .doc(id)
    .set(data)
    .then(ref => {
      return id;
    });

  return docID;
}

async function delDocument(firebase, collection, id) {
  const firestore = firebase.firestore();
  return await firestore
    .collection(collection)
    .doc(id)
    .delete()
    .then(() => {
      return true;
    })
    .catch(() => false);

  // return false
}

function createDocumentButton(collection, data) {
  return (
    <FirestoreMutation path={collection} type="add">
      {({ runMutation }) => (
        <Button
          size="md"
          color="info"
          // data-testid="add-document"
          onClick={async () => {
            const { key } = await runMutation(data);
            if (key === null) return;
            return key;
          }}
        >
          Add New
        </Button>
      )}
    </FirestoreMutation>
  );
}
// Do not use
function deleteDocument(pathWithKey) {
  return (
    <FirestoreMutation path={pathWithKey} type="delete">
      {({ runMutation }) => (
        <Button
          size="md"
          color="danger"
          onClick={() => {
            runMutation().then(res => {
              console.log("Ran mutation ", res);
            });
          }}
        >
          <Icon link={true} name="trash" />
        </Button>
      )}
    </FirestoreMutation>
  );
}

export {
  updateDocumentButton,
  createDocumentButton,
  updateDocument,
  createDocument,
  createDocDefnID,
  deleteDocument,
  delDocument,
  updateSubcollectionDocument,
  delSubcollectionDocument,
  createSubcollectionDocument,
  createAttrSubcollDocDefID
};
