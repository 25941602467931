import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Typography, Switch } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: { marginBottom: 15 },
}));

const FAQVisibility = (props) => {
  const { published, handleFAQUpdate, className, ...rest } = props;

  const classes = useStyles();

  const handleChecked = (event) => {
    handleFAQUpdate &&
      handleFAQUpdate({ [event.target.name]: event.target.checked });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Typography variant="h4">FAQ Visibility:</Typography>
        {/* <br /> */}
        <Switch
          checked={published === "publish" || published}
          onChange={handleChecked}
          name="status"
          value={published}
          title={
            published === "publish" || published ? "Published" : "Not Published"
          }
          inputProps={{ "aria-label": "secondary checkbox" }}
        />
      </CardContent>
    </Card>
  );
};

FAQVisibility.propTypes = {
  published: PropTypes.bool,
  className: PropTypes.string,
  handleFAQUpdate: PropTypes.func.isRequired,
};

export default FAQVisibility;
