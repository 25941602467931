import React, { lazy } from 'react';
import { Redirect /*, Switch, Route*/ } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import DashboardAnalyticsView from './views/DashboardAnalytics';
import DashboardDefaultView from './views/DashboardDefault';
import OverviewView from './views/Overview';
import PresentationView from './views/Presentation';


import PrivateRoute from './utils/privateRoute'


const routes = [
    {
      path: '/',
      exact: true,
      component: () => <Redirect to="/dashboard" />
    },
    {
      path: '/login',
      component: AuthLayout,
      routes: [
        {
          path: '/login',
          exact: true,
          component: lazy(() => import('views/Login'))
        },
        // {
        //   path: '/register',
        //   exact: true,
        //   component: lazy(() => import('views/Register'))
        // },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    },
    {
      path: '/errors',
      component: ErrorLayout,
      routes: [
        {
          path: '/errors/error-401',
          exact: true,
          component: lazy(() => import('views/Error401'))
        },
        {
          path: '/errors/error-404',
          exact: true,
          component: lazy(() => import('views/Error404'))
        },
        {
          path: '/errors/error-500',
          exact: true,
          component: lazy(() => import('views/Error500'))
        },
        // {
        //   component: () => <Redirect to="/errors/error-404" />
        // }
      ]
    },
    {
      route: '*',
      component: PrivateRoute, //All of these routes are protected in case there is no user
      routes: [
        {
          path: '/calendar',
          exact: true,
          component: lazy(() => import('views/Calendar'))
        },
        {
          path: '/changelog',
          exact: true,
          component: lazy(() => import('views/Changelog'))
        },
        {
          path: '/chat',
          exact: true,
          component: lazy(() => import('views/Chat'))
        },
        {
          path: '/chat/:id',
          exact: true,
          component: lazy(() => import('views/Chat'))
        },
        {
          path: '/dashboard',
          exact: true,
          component: DashboardAnalyticsView
        },
        {
          path: '/dashboards/default',
          exact: true,
          component:DashboardDefaultView
        },
        {
          path: '/orders/:id',
          exact: true,
          component: lazy(() => import('views/InvoiceDetails'))
        },
        {
          path: '/kanban-board',
          exact: true,
          component: lazy(() => import('views/KanbanBoard'))
        },
        {
          path: '/layout/posts',
          exact: true,
          component: lazy(() => import('views/PostLayoutList'))
        },
        {
          path: '/layout/posts/create',
          exact: true,
          component: lazy(() => import('views/PostLayoutCreate'))
        },
        {
          path: '/layout/posts/:id',
          exact: true,
          component: lazy(() => import('views/PostLayoutDetails'))
        },
        {
          path: '/layout/faqs',
          exact: true,
          component: lazy(() => import('views/FAQLayoutList'))
        },
        {
          path: '/layout/faqs/create',
          exact: true,
          component: lazy(() => import('views/FAQLayoutCreate'))
        },
        {
          path: '/layout/faqs/:id',
          exact: true,
          component: lazy(() => import('views/FAQLayoutDetails'))
        },
        {
          path: '/layout/faqs/:id/:tab',
          exact: true,
          component: lazy(() => import('views/FAQLayoutDetails'))
        },
        {
          path: '/layout/pages',
          exact: true,
          component: lazy(() => import('views/PagesLayoutList'))
        },
        {
          path: '/layout/pages/create',
          exact: true,
          component: lazy(() => import('views/PagesLayoutCreate'))
        },
        {
          path: '/layout/pages/:id',
          exact: true,
          component: lazy(() => import('views/PagesLayoutDetails'))
        },
        {
          path: '/layout/pages/:id/:tab',
          exact: true,
          component: lazy(() => import('views/PagesLayoutDetails'))
        },
        {
          path: '/layout/promotions',
          exact: true,
          component: lazy(() => import('views/PromotionLayoutList'))
        },
        {
          path: '/layout/promotions/create',
          exact: true,
          component: lazy(() => import('views/PromotionLayoutCreate'))
        },
        {
          path: '/layout/promotions/:id',
          exact: true,
          component: lazy(() => import('views/PromotionLayoutDetails'))
        },
        {
          path: '/layout/promotions/:tab',
          exact: true,
          component: lazy(() => import('views/PromotionLayoutList'))
        },
        {
          path: '/layout/posts/:id/:tab',
          exact: true,
          component: lazy(() => import('views/PostLayoutDetails'))
        },
        {
          path: '/layout/hero',
          exact: true,
          component: lazy(() => import('views/HeroLayoutList'))
        },
        {
          path: '/layout/hero/create',
          exact: true,
          component: lazy(() => import('views/HeroLayoutCreate'))
        },
        {
          path: '/layout/hero/:id',
          exact: true,
          component: lazy(() => import('views/HeroLayoutDetails'))
        },
        {
          path: '/mail',
          exact: true,
          component: lazy(() => import('views/Mail'))
        },
        {
          path: '/management/customers',
          exact: true,
          component: lazy(() => import('views/CustomerManagementList'))
        },
        {
          path: '/management/customers/:id',
          exact: true,
          component: lazy(() => import('views/CustomerManagementDetails'))
        },
        {
          path: '/management/customers/:id/:tab',
          exact: true,
          component: lazy(() => import('views/CustomerManagementDetails'))
        },
        {
          path: '/management/projects',
          exact: true,
          component: lazy(() => import('views/ProjectManagementList'))
        },
        {
          path: '/management/orders',
          exact: true,
          component: lazy(() => import('views/OrderManagementList'))
        },
        {
          path: '/management/orders/create',
          exact: true,
          component: lazy(() => import('views/OrderManagementCreate'))
        },
        {
          path: '/management/orders/:id',
          exact: true,
          component: lazy(() => import('views/OrderManagementDetails'))
        },
        {
          path: '/management/products',
          exact: true,
          component: lazy(() => import('views/ProductManagementList'))
        },
        {
          path: '/management/products/create',
          exact: true,
          component: lazy(() => import('views/ProductManagementCreate'))
        },
        {
          path: '/management/products/:id',
          exact: true,
          component: lazy(() => import('views/ProductManagementDetails'))
        },
        {
          path: '/management/products/:id/:tab',
          exact: true,
          component: lazy(() => import('views/ProductManagementDetails'))
        },
        {
          path: '/management/attributes',
          exact: true,
          component: lazy(() => import('views/AttributesManagementDetails'))
        },
        {
          path: '/management/attributes/:tab',
          exact: true,
          component: lazy(() => import('views/AttributesManagementDetails'))
        },
        {
          path: '/marketing/automations',
          exact: true,
          component: lazy(() => import('views/AutomationsMarketingList'))
        },
        {
          path: '/marketing/coupons',
          exact: true,
          component: lazy(() => import('views/CouponMarketingDetails'))
        },
        {
          path: '/marketing/coupons/:tab',
          exact: true,
          component: lazy(() => import('views/CouponMarketingDetails'))
        },
        {
          path: '/marketing/upsells',
          exact: true,
          component: lazy(() => import('views/UpSellMarketingDetails'))
        },
        {
          path: '/marketing/upsells/:tab',
          exact: true,
          component: lazy(() => import('views/UpSellMarketingDetails'))
        },
        {
          path: '/marketing/email-builder',
          exact: true,
          component: lazy(() => import('views/EmailBuilder'))
        },
        {
          path: '/overview',
          exact: true,
          component: OverviewView
        },
        {
          path: '/presentation',
          exact: true,
          component: PresentationView
        },
        {
          path: '/profile/:id',
          exact: true,
          component: lazy(() => import('views/Profile'))
        },
        {
          path: '/profile/:id/:tab',
          exact: true,
          component: lazy(() => import('views/Profile'))
        },
        {
          path: '/projects/create',
          exact: true,
          component: lazy(() => import('views/ProjectCreate'))
        },
        {
          path: '/projects/:id',
          exact: true,
          component: lazy(() => import('views/ProjectDetails'))
        },
        {
          path: '/projects/:id/:tab',
          exact: true,
          component: lazy(() => import('views/ProjectDetails'))
        },
        {
          path: '/projects',
          exact: true,
          component: lazy(() => import('views/ProjectList'))
        },
        {
          path: '/settings',
          exact: true,
          component: lazy(() => import('views/Settings'))
        },
        {
          path: '/settings/:tab',
          exact: true,
          component: lazy(() => import('views/Settings'))
        },
        {
          path: '/social-feed',
          exact: true,
          component: lazy(() => import('views/SocialFeed'))
        },
        {
          path: '/getting-started',
          exact: true,
          component: lazy(() => import('views/GettingStarted'))
        },
        {
          component: () => <Redirect to="/errors/error-404" />
        }
      ]
    }
  ];
  
  export default routes;
  