/**
 * Template interface for creating HTML snippets safely. Uses the tagged
 * template syntax:
 *
 *     text = "x > y"
 *     snippet = tagHtml`<span>${text}</span>`
 *     // snippet === <span>x &gt y</span>
 *
 * The HTML in the template will be preserved, but all substitutions will
 * be escaped.
 *
 * See https://mzl.la/2EnDB7Q for details on the function signature for
 * tagged template literals.
 *
 * Some safety notes: in order to prevent additional vulnerability, this
 * function should never be used to insert template content into javascript.
 * Additionally, do not use unquted attributes (such as
 * tagHtml`<div class=${cls}></div>`).
 */
// TODO - this should just be removed as we migrate stuff over to react.
import escapeHtml from "lodash/escape";

const strIsEmpty = (value: any): boolean => value === null || value === "";

export const tagHtml = (
    rawParts: string[],
    ...substitutions: any[]
  ): string => {
    const result: any[] = [];
  
    rawParts.forEach((raw: any, index: number) => {
      const substitution = substitutions[index];
      if (!strIsEmpty(raw)) result.push(raw);
      if (!strIsEmpty(substitution)) result.push(escapeHtml(substitution));
    });
  
    return result.join("");
  };
  