const customer = {
  id: 'customer',
  roleName: 'Customer',
  status: false,
  privileges: [{
    label: "View/ Edit Profile",
    status: true,
  },
  {
    label: "View Products",
    status: true,
  },
  {
    label: "View Posts",
    status: true,
  },
  {
    label: "View Categories",
    status: true,
  },
  {
    label: "View Own Orders",
    status: true,
  },

]
}

const admin = {
  id: "admin",
  roleName: "Admin",
  status: false,
  privileges: [
    {
      label: "All permissions granted",
      status: true,
    },
  ],
};

const customerService = {
  id: "customerService",
  roleName: "Customer Service",
  status: false,
  privileges: [
    {
      label: "View/ Edit Orders",
      status: true,
    },
    {
      label: "View/ Edit Custmers",
      status: true,
    },
    {
      label: "View/ Edit Products",
      status: true,
    },
    {
      label: "View/ Edit Settings",
      status: false,
    },
    {
      label: "View/ Edit Analytics",
      status: false,
    },
    {
      label: "View/ Edit Revenues",
      status: false,
    },
    {
      label: "Delete",
      status: false,
    },
    {
      label: "Change History",
      status: false,
    },
  ],
};

const marketing = {
  id: "marketing",
  roleName: "Marketing",
  status: false,
  privileges: [
    {
      label: "Edit Page Building",
      status: true,
    },
    {
      label: "Edit Deals",
      status: true,
    },
    {
      label: "Edit Blogs",
      status: true,
    },
    {
      label: "Edit Products",
      status: false,
    },
    {
      label: "Edit Orders",
      status: false,
    },
    {
      label: "Edit Analytics",
      status: false,
    },
    {
      label: "Edit Revenues",
      status: false,
    },
    {
      label: "Delete",
      status: false,
    },
    {
      label: "Change History",
      status: false,
    },
  ],
};

export default [customer, admin, customerService, marketing];
