import { useContext } from 'react';
import { __RouterContext as RouterContext } from 'react-router';


const useRouter = () => useContext(RouterContext);


export function useParams() {
    const { match } = useRouter();
    return match.params;
}

export default useRouter;