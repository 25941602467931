
import React, { useState } from 'react';
import { FormComponent, FormContainer } from 'react-authorize-net';
import clsx from 'clsx';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Typography,
  TextField,
  Button,
  colors
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

let clientKey = process.env.REACT_APP_AUTHORIZENET_CLIENTKEY;
let apiLoginId = process.env.REACT_APP_AUTHORIZENET_LOGINID;

const CreditPayment = props => {

  // const statusState = ['unpaid', 'paid', 'failure']
  const [status, setStatus] = useState({ status: 'unpaid' });
  const classes = useStyles();

  const { open, onClose, order, newCustomer, updateField, className, ...rest } = props;


  if (!open) {
    return null;
  }

  // function returnState(state) {

    // return state.status.paid || state.status.unpaid || state.status.failure
    // switch (state) {
    //     case "unpaid":
    //         return 'unpaid'
    //     case "paid":
    //         return 'paid'
    //     case "failure":
    //         return [state]
    //     default:
    //         return 'unpaid'
    // }
  // }

  const onErrorHandler = (response) => {
    setStatus({
      status: ["failure", response.messages.message.map(err => err.text)]
    });
  };

  const onSuccessHandler = (response) => {
    // Process API response on your backend...
    setStatus({ status: ["failure", []] });
  };

  const ErrorComponent = (props) => (

    <div>
      <Typography style={{fontWeight: 500}} >
        Failed to process payment
        </Typography>
      {props.errors.map((error, i)  => (
        <Typography key={i}>{error}</Typography>
      ))}
      <Button onClick={props.onBackButtonClick}>Go Back</Button>
    </div>
  );


  return (
    <Modal
      onClose={onClose}
      open={open}
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <form>
          <CardContent>
            {status.status === "paid" ? (
              <TextField fontWeight={"500"} fontSize={3} mb={4}>
                Thank you for your payment!
                            </TextField>
            ) : status.status === "unpaid" ? (
              <FormContainer
                environment="sandbox"
                onError={onErrorHandler}
                onSuccess={onSuccessHandler}
                amount={23}
                component={FormComponent}
                clientKey={clientKey}
                apiLoginId={apiLoginId}
              />
            ) : status.status[0] === "failure" ? (
              <ErrorComponent
                onBackButtonClick={() => setStatus({ status: "unpaid" })}
                errors={status.status[1]}
              />
            ) : null}
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              onClick={onClose}
              variant="contained"
            >
              Close
            </Button>
            <Button
              className={classes.saveButton}
              //   onClick={handleCheckItems}
              variant="contained"
            >
              Save
            </Button>
          </CardActions>
        </form>
      </Card>
    </Modal>
  )
}

export default CreditPayment;
