import React, { useState, useEffect, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TextField,
  TablePagination,
  colors
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

// import axios from "utils/axios";
import { Label } from "components";
import { capitalize } from "helpers";
import moment from "moment";

import { useCollection } from "react-firebase-hooks/firestore";
import { updateDocument } from "data/FirestoreUpdate";
import { FirebaseContext } from "data/Firebase";
import { firebaseDataMap } from "helpers";
import "firebase/firestore";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 700
  },
  progressContainer: {
    padding: theme.spacing(3),
    display: "flex",
    justifyContent: "center"
  },
  actions: {
    justifyContent: "flex-end"
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1)
  }
}));

const LatestOrders = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const [orders, setOrders] = useState([]);
  const options = [
    "new order",
    "processing",
    "out for delivery",
    "payment received",
    "shipped",
    "delivered",
    "tracking number sent",
    "completed",
    "cancelled",
    "refunded"
  ];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const app = useContext(FirebaseContext);
  const collection = "orders";
  const [query] = useState(
    app
      .firestore()
      .collection(collection)
      .limit(20)
      .orderBy("timestamp", "desc")
  );

  const [value] = useCollection(query, {
    snapshotListenOptions: { includeMetadataChanges: true }
  });

  useEffect(() => {
    const fetchOrder = async () => {
      const newOrders = await firebaseDataMap(value.docs);

      setOrders([...newOrders]);
    };
    value && fetchOrder();
  }, [value]);

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  function paginate(array, page_size, page_number) {
    return array.slice(page_number * page_size, (page_number + 1) * page_size);
  }

  const paymentStatusColors = {
    cancelled: "lightgray",
    unfulfilled: "lightgray",
    processing: colors.brown[100],
    // colors.orange[600],
    completed: colors.green[300],
    "payment received": colors.green[300],
    // colors.green[600],
    refunded: colors.red[300],
    fulfilled: colors.green[300],
    "new order": colors.red[300],
    // 'aliceblue',
    "out for delivery": colors.orange[400]
  };

  const handleStatusChange = event => {
    event.persist();
    updateStatus(event);
  };

  function moveOrAddOption(opt) {
    if (!options.includes(opt)) {
      return [opt, ...options];
    } else {
      const newArr = options.filter(item => item !== opt);
      newArr.splice(0, 0, opt);
      return newArr;
    }
  }

  function updateStatus(e) {
    updateDocument(app, collection, e.target.name, {
      status: e.target.value.toLowerCase()
    });
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        // action={<GenericMoreButton />}
        title="Latest Orders"
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar options={{ suppressScrollY: true }}>
          <div className={classes.inner}>
            {orders && (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Order: Customer</TableCell>
                    <TableCell>Created</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Payment Method</TableCell>
                    <TableCell>Delivery Method</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginate(orders, rowsPerPage, page).map(order => (
                    <TableRow key={order.id}>
                      <TableCell>
                        <RouterLink to={`/management/orders/${order.id}`}>
                          {`#${order.order}:  ${capitalize(
                            order.name_first
                          )} ${capitalize(order.name_last)}`}
                        </RouterLink>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {moment(new Date(order.timestamp)).format(
                            "MM ddd DD YYYY | hh:mm a"
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Label
                          color={
                            paymentStatusColors[order.status.toLowerCase()]
                          }
                          variant="contained"
                        >
                          <TextField
                            fullWidth
                            name={order.id}
                            // color={paymentStatusColors[order.status]}

                            onChange={handleStatusChange}
                            select
                            // eslint-disable-next-line react/jsx-sort-props
                            SelectProps={{ native: true }}
                            style={{
                              selected: {
                                background:
                                  paymentStatusColors[
                                    order.status.toLowerCase()
                                  ]
                              }
                            }}
                            value={order.status}
                          >
                            {moveOrAddOption(order.status).map(opt => (
                              <option key={opt} value={opt}>
                                {capitalize(opt)}
                              </option>
                            ))}
                          </TextField>
                        </Label>
                      </TableCell>
                      <TableCell>
                        $
                        {order.order_total
                          ? order.order_total.toFixed(2)
                          : "N/A"}
                      </TableCell>
                      <TableCell>{order.method_payment}</TableCell>
                      <TableCell>{order.method}</TableCell>
                      <TableCell align="right">
                        <Button
                          color="primary"
                          component={RouterLink}
                          size="small"
                          to={`/management/orders/${order.id}`}
                          variant="outlined"
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={orders.length}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
        <Button
          color="primary"
          component={RouterLink}
          size="small"
          to="management/orders"
          variant="text"
        >
          See all
          <ArrowForwardIcon className={classes.arrowForwardIcon} />
        </Button>
      </CardActions>
    </Card>
  );
};

LatestOrders.propTypes = {
  className: PropTypes.string
};

export default LatestOrders;
