import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
// import { useScript } from "helpers";
// import Iframe from "react-iframe";

import { Page } from "components";
import {
  Header,
  Overview,
  // FinancialStats,
  EarningsSegmentation,
  TopReferrals,
  MostProfitableProducts,
  CustomerActivity,
  LatestOrders,
  GoogleAnalytics,
} from "./components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  container: {
    "& > *": {
      height: "100%",
    },
  },
}));

// const OrganicTraffic = props => {
//   // console.log('this is the loaded and error if any', loaded, error)
//   return <React.Fragment>
//     <Iframe
//       url={'https://visual-demand.agencyanalytics.app/#/report/2530695/tkn.e4232ac9811bdb6bfce2d959b6cd088c'}
//       width="100%"
//       height="100vh"
//       // id="myId"
//       // className={classes.iframeClass}
//       styles={{
//         width: '100%',
//         height: '80vh'
//       }}
//     // display="initial"
//     // position="relative"
//     />

//   </React.Fragment>
// }

const DashboardAnalytics = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Analytics Dashboard">
      <Header />
      <Grid className={classes.container} container spacing={3}>
        <Grid item xs={12}>
          <Overview />
        </Grid>
        {/* <Grid item lg={8} xl={9} xs={12}>
          <Iframe
            url={
              "https://visual-demand.agencyanalytics.app/#/report/2530700/tkn.8b9e779787f008706c5825011b63a464"
            }
            width="100%"
            height="500"
            // id="myId"
            // className={classes.iframeClass}
            styles={{
              width: "100%",
              height: 500,
            }}
            // display="initial"
            // position="relative"
          />
          <FinancialStats />
        </Grid> */}
        <Grid item lg={4} xl={3} xs={12}>
          <EarningsSegmentation />
          <br />
          <CustomerActivity />
        </Grid>
        <Grid item lg={8} xs={12}>
          <LatestOrders />
        </Grid>
        <Grid item xs={12}>
          <GoogleAnalytics />
        </Grid>
        <Grid item lg={8} xs={12}>
          <MostProfitableProducts />
        </Grid>
        <Grid item lg={4} xs={12}>
          <TopReferrals />
        </Grid>
      </Grid>
    </Page>
  );
};

export default DashboardAnalytics;
