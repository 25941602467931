import createActiveUsersModule from "./createActiveUsersModule"
// Copyright 2015 Google Inc. All rights reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

// /* global gapi */

export default () =>
  window.gapi.analytics.ready(function () {

    createActiveUsersModule();

    var activeUsers = new window.gapi.analytics.ext.ActiveUsers({
      container: "active-users-container",
      pollingInterval: 5,
    });
    
    /**
     * Add CSS animation to visually show the when users come and go.
     */
    activeUsers.once("success", function (response) {
      var timeout;

      this.on("change", function (data) {
        var element = this.container.firstChild;
        var animationClass = data.delta > 0 ? "is-increasing" : "is-decreasing";
        element.className += " " + animationClass;

        clearTimeout(timeout);
        timeout = setTimeout(function () {
          element.className = element.className.replace(
            / is-(increasing|decreasing)/g,
            ""
          );
        }, 3000);
      });
    });
  });
