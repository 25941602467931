import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  colors
} from '@material-ui/core';

import getInitials from 'utils/getInitials';

const useStyles = makeStyles(() => ({
  root: {},
  header: {
    paddingBottom: 0
  },
  content: {
    paddingTop: 0
  },
  actions: {
    backgroundColor: colors.grey[50]
  },
  manageButton: {
    width: '100%'
  }
}));

const Categories = props => {
  const { categories, post, className, ...rest } = props;

  const classes = useStyles();

  const findCategory = (cat) => {
    const arr = categories ? categories.filter((category) => category.id === cat) : []
    return arr[0] ? arr[0] : { name: 'Not Found' }
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        className={classes.header}
        title="Post Categories"
        titleTypographyProps={{
          variant: 'overline'
        }}
      />
      <CardContent className={classes.content}>

        <List>
          {(post.categories && post.categories.length > 0 && categories) && post.categories.map((category, i) => (
            <ListItem
              disableGutters
              key={i}
            >
              <ListItemAvatar>
                <Avatar
                  alt="Author"
                  className={classes.avatar}
                  src={category.avatar || ''}
                >
                  {getInitials(findCategory(category).name)}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={findCategory(category).name}
                primaryTypographyProps={{ variant: 'h6' }}
                secondary={(findCategory(category).slugs && findCategory(category).slugs.length > 0) 
                  ? findCategory(category).slugs.join() 
                  : 'No Slugs for Category!!'}
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

Categories.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.array
};

export default Categories;
