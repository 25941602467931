import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { slugify } from 'helpers'

import { Holder, HeroContent } from './components';

const useStyles = makeStyles(theme => ({
  root: {},
  deliverables: {
    marginTop: theme.spacing(3)
  },
  categories: {
    marginTop: theme.spacing(3)
  }
}));

const HeroEdit = props => {
  const { heroExisting, app, createNew, edit, editField, className, ...rest } = props;
  const hero = heroExisting
  const classes = useStyles();

  const handleheroUpdate = dic => {
    // Automatically update the hero sku
      if(dic.title){
        dic.sku = slugify(dic.title)
      }
      if(dic.slug){ // Slugify the handle url
        dic.slug = slugify(dic.slug)
      }
      editField && editField(dic)
  }

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      <Grid
        item
        lg={4}
        xl={3}
        xs={12}
      >
        <Holder app={app} hero={hero} handleheroUpdate={handleheroUpdate} />
      </Grid>
      <Grid
        item
        lg={8}
        xl={9}
        xs={12}
      >
        {/* <Holder hero={hero} /> */}
        <HeroContent
          hero={hero}
          handleheroUpdate={handleheroUpdate}
        />
        
      </Grid>
    </Grid>
  );
};

HeroEdit.propTypes = {
  className: PropTypes.string,
  // hero: PropTypes.object.isRequired,
  editField: PropTypes.func.isRequired,
  categories: PropTypes.array
};

export default HeroEdit;
