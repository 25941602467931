import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Avatar,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  Input,
  Chip
} from "@material-ui/core";
// import CKEditor from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import WordCount from '@ckeditor/ckeditor5-word-count/src/wordcount';

import { FirebaseContext } from "data/Firebase";
import { firebaseDataMap } from "helpers";
import { useCollection } from "react-firebase-hooks/firestore";
import "firebase/firestore";
import "firebase/firebase-storage";

import { Editor } from 'components'

import AttributesModal from "../AttributesModal";

const useStyles = makeStyles(theme => ({
  root: { marginBottom: 15 },
  editor:{maxHeight: '20vh'},
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%"
  },
  attributes: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 150
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText
  },
  chipRed: {
    backgroundColor: theme.palette.error.light,
    margin: 2,
    color: "white"
  },
  attributesHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
}));

const ProductContent = props => {
  const classes = useStyles();

  const productAttributesCollection = "product-attributes";
  const collectionPath = 'products'
  const app = useContext(FirebaseContext);

  const {
    product,
    productId,
    categories,
    vendors,
    brands,
    handleProductUpdate,
    className,
    ...rest
  } = props;

  const [attrQuery, setAttrQuery] = useState(null);
  // const [currAttr, setCurrAttr] = useState("");

  const [prodAttrs/*, loadingProdAttrs, errorProdAttrs*/] = useCollection(
    app.firestore().collection(productAttributesCollection),
    {
      snapshotListenOptions: { includeMetadataChanges: true }
    }
  );

  const [attrValues/*, loadingAttrValues, errorAttrValues*/] = useCollection(
    attrQuery,
    {
      snapshotListenOptions: { includeMetadataChanges: true }
    }
  );

  const updateAttrValueQuery = name => {
    if (name) {
      setAttrQuery(
        app
          .firestore()
          .collection(productAttributesCollection)
          .doc(name)
          .collection("values")
      );
      // setCurrAttr(name)
    } else {
      setAttrQuery(null);
      // setCurrAttr('')
    }
  };



  const theme = useTheme();

  // const [updatedDescription, setDescription] = useState(null);
  const [title, setTitle] = useState(product.title || "");
  // const [editorState, setEditorState] = useState(null)
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  function containsCategory(arr, id) {
    if (!arr) {
      return { name: "Category does not exist" };
    }
    var found = arr.find(category => {
      return category.id === id;
    });

    if (!found) return { name: "Category no longer exists!" };

    return found;
  }

  function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
  }

  const convertObjToArr = (obj) => {
    if(!obj) return []; // There is nothing to check, return empty array

    if (Array.isArray(obj)) return obj; // Already an array, return this

    if (typeof obj === "object" && !isEmptyObject(obj)) { // First check if the value is an object
      return Object.keys(obj); // return array of object values 
    }

    return []; // If obj is either a string or integer return an empty array
  }

  function containsBrand(arr, id) {
    if (!arr) {
      return { name: "Brand does not exist" };
    }
    var found = arr.find(brand => {
      return brand.id === id;
    });

    if (!found) return { name: "Brand no longer exists!" };
    return found;
  }

  //TODO: needs to return an array rather than single
  function containsVendor(arr, prodVendors) {
    if (!arr) return { name: "No Vendors Detected!" };

    var found = arr.find(vendor => {
      return vendor.id === prodVendors // return the vendor that matches the id
    });

    if (!found) return { name: "Vendor no longer exists!" };

    return found;
  }

  const handleSelectGroups = event => {
    handleProductUpdate &&
      handleProductUpdate({ [event.target.name]: event.target.value });
  };

  const handleSelectVendors = event => {
    const selectedValues = event.target.value
    if(!Array.isArray(event.target.value)) return;// Not an array so don't bother and just return

    let createdDic = {}
    selectedValues.forEach((item) => createdDic[item] = true)

    handleProductUpdate &&
      handleProductUpdate({ [event.target.name]: createdDic });
  };

  function getStyles(name, valName, theme) {
    return {
      color: valName && valName.indexOf(name) === -1 ? "grey" : "white",
      backgroundColor: valName && valName.indexOf(name) === -1 ? "white" : "#96748c"
    };
  }

  //TODO: Need to handle the floats and string for each of the fields or it messes up the queries including inventory sorting
  const handleEdit = (name, value) => {
    handleProductUpdate && handleProductUpdate({ [name]: value });
  };

  // const handleDescriptionEdit = () => {
  //   // console.log('this is the data sent', 'description', updatedDescription)

  //   if (handleProductUpdate !== null) {
  //     handleProductUpdate({ description: updatedDescription || "" });
  //   }
  // };

  return (
    <React.Fragment>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <Typography variant="h4">Product Name:</Typography>
          <Typography>{title}</Typography>
          <TextField
            required
            id="standard-required"
            label="Product Name"
            error={!title}
            name="title"
            defaultValue={title || ""}
            onChange={event => setTitle(event.target.value)}
            className={classes.textField}
            margin="normal"
          />
          <Button
            color="secondary"
            variant="contained"
            onClick={() => handleEdit("title", title || "")}
          >
            Update Title
          </Button>
          {/* Only show the description editor if the product is new */}
          {!productId && <React.Fragment>
            <Typography variant="h4">Product Description:</Typography>
            <br />

            <Editor
              text={'<p>Change product description</p>'}
              collectionPath={collectionPath}
              type='product'
              editField={handleProductUpdate}
              className={classes}
            />
          </React.Fragment>}

          {/* <br />
          <br />
          <CKEditor
            editor={ClassicEditor}
            // config={{
            //     plugins: [ WordCount],
            //     wordCount: {
            //         onUpdate: stats => {
            //             // Prints the current content statistics.
            //             console.log( `Characters: ${ stats.characters }\nWords: ${ stats.words }` );
            //         }
            //     }
            // }}
            data={product.description || ""}
            name="description"
            onInit={editor => {
              // if (!editorState){
              //     setEditorState(editor)
              // }
              if (updatedDescription !== editor.getData()) {
                setDescription(editor.getData());
              }
              // You can store the "editor" and use when it is needed.
              // console.log('Editor is ready to use!', editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              // console.log({ event, editor, data });
              if (updatedDescription !== data) {
                setDescription(data);
              }
            }}
            onBlur={editor => {
              console.log("Blur.", editor);
            }}
            onFocus={editor => {
              console.log("Focus.", editor);
            }}
          />
          <br />
          <Button
            color={updatedDescription ? "secondary" : "primary"}
            variant="contained"
            onClick={() => {
              handleDescriptionEdit();
            }}
          >
            Update Description
          </Button> */}
          <br />
          <br />
          <TextField
            // disabled
            required
            id="inventory-standard-required"
            label="Inventory"
            error={!product.inventory}
            name="inventory"
            type="number"
            defaultValue={product.inventory || 0}
            onChange={event =>
              handleEdit(
                event.target.name,
                event.target.value ? parseInt(event.target.value) : 0
              )
            }
            className={classes.attributes}
            margin="normal"
            variant="outlined"
          />
          <br />
          <Typography>Product Pricing</Typography>
          <TextField
            // disabled
            required
            id="price-standard-required"
            label="Price"
            type="number"
            error={!product.price}
            name="price"
            defaultValue={product.price || "0.00"}
            onChange={event =>
              handleEdit(
                event.target.name,
                event.target.value ? parseFloat(event.target.value) : 0.0
              )
            }
            className={classes.attributes}
            margin="normal"
            variant="outlined"
          />
          <TextField
            // disabled
            // required
            id="price_sale-standard-required"
            label="Sale Price"
            type="number"
            // error={!product.price}
            name="price_sale"
            defaultValue={product.price_sale || "0.00"}
            onChange={event =>
              handleEdit(
                event.target.name,
                event.target.value ? parseFloat(event.target.value) : 0.0
              )
            }
            className={classes.attributes}
            margin="normal"
            variant="outlined"
          />
          <TextField
            // disabled
            // required
            id="price_compare-standard-required"
            label="Compare at Price"
            type="number"
            // error={!product.price}
            name="price_compare"
            defaultValue={product.price_compare || "0.00"}
            onChange={event =>
              handleEdit(
                event.target.name,
                event.target.value ? parseFloat(event.target.value) : 0.0
              )
            }
            className={classes.attributes}
            margin="normal"
            variant="outlined"
          />
          <TextField
            // disabled
            required
            id="price_cost-standard-required"
            label="Product Cost"
            error={!product.price_cost}
            name="price_cost"
            type="number"
            defaultValue={product.price_cost || "0.00"}
            onChange={event =>
              handleEdit(
                event.target.name,
                event.target.value ? parseFloat(event.target.value) : 0.0
              )
            }
            className={classes.attributes}
            margin="normal"
            variant="outlined"
          />
          <br />
          <Typography>Product Details</Typography>
          <TextField
            required
            id="thc-standard-required"
            label="THC %"
            error={!product.thc}
            type="number"
            name="thc"
            defaultValue={product.thc || "0"}
            onChange={event =>
              handleEdit(
                event.target.name,
                event.target.value ? parseFloat(event.target.value) : 0.0
              )
            }
            className={classes.attributes}
            margin="normal"
            variant="outlined"
          />
          <TextField
            required
            id="thc_mg-standard-required"
            label="THC mg"
            type="number"
            error={!product.thc_mg}
            name="thc_mg"
            defaultValue={product.thc_mg || "0"}
            onChange={event =>
              handleEdit(
                event.target.name,
                event.target.value ? parseFloat(event.target.value) : 0.0
              )
            }
            className={classes.attributes}
            margin="normal"
            variant="outlined"
          />
          <TextField
            required
            id="cbd-standard-required"
            label="CBD %"
            error={!product.cbd}
            name="cbd"
            type="number"
            defaultValue={product.cbd || "0"}
            onChange={event =>
              handleEdit(
                event.target.name,
                event.target.value ? parseFloat(event.target.value) : 0.0
              )
            }
            className={classes.attributes}
            margin="normal"
            variant="outlined"
          />
          <TextField
            required
            id="cbd_mg-standard-required"
            label="CBD mg"
            type="number"
            error={!product.cbd_mg}
            name="cbd_mg"
            defaultValue={product.cbd_mg || "0"}
            onChange={event =>
              handleEdit(
                event.target.name,
                event.target.value ? parseFloat(event.target.value) : 0.0
              )
            }
            className={classes.attributes}
            margin="normal"
            variant="outlined"
          />
        </CardContent>
      </Card>
      <Card>
        <CardContent>
          <br />
          {productId && <div className={classes.attributesHeader}>
            <Typography variant="h4">Product Attributes:</Typography>
            {prodAttrs && handleProductUpdate && (
              <AttributesModal
                productAttributes={product.attributes}
                availableAttributes={firebaseDataMap(prodAttrs.docs)}
                collectionListByProd={firebaseDataMap(attrValues?.docs)}
                updateAttrValueQuery={updateAttrValueQuery}
                handleProductUpdate={handleProductUpdate}
                app={app}
                productId={productId}
              />
            )}
          </div>}

          {product && (
            <List>
              {product.attributes &&
                product.attributes.length > 0 &&
                product.attributes.map((attribute, i) => (
                  <ListItem disableGutters key={i}>
                    <ListItemAvatar>
                      <Avatar
                        alt="Author"
                        className={classes.avatar}
                        src={attribute.avatar || ""}
                      >
                        {/* {getInitials(findCategory(category).name)} */} PR
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={attribute.name} //findCategory(category).name
                      primaryTypographyProps={{ variant: "h6" }}
                      secondary={attribute.values + " "}
                    />
                  </ListItem>
                ))}
            </List>
          )}
          <br />
          <Typography variant="h4">Product Categories:</Typography>
          <br />
          <InputLabel htmlFor="select-multiple-chip">
            Select Product Categories
          </InputLabel>
          {product && (
            <Select
              multiple
              displayEmpty // Allows you to show a custom empty chip
              value={product.categories || []}
              onChange={handleSelectGroups}
              name="categories"
              input={<Input id="select-multiple-chip" />}
              renderValue={selected => (
                <div className={classes.chips}>
                  {selected.length > 0 && categories ? (
                    selected.map(val => {
                      const cate = containsCategory(categories, val);
                      return (
                        <Chip
                          key={cate.name}
                          label={cate.name}
                          className={classes.chip}
                        />
                      );
                    })
                  ) : (
                      <Chip
                        key={"No Selected Categories!!"}
                        label={"No Selected Categories!!"}
                        className={classes.chipRed}
                      />
                    )}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {categories &&
                categories.length > 0 &&
                categories.map(cat => (
                  <MenuItem
                    key={cat.name || "Error"}
                    value={cat.id || "Error"}
                    style={
                      product.categories &&
                      getStyles(cat.id, product.categories, theme)
                    }
                  >
                    {cat.name || "Error"}
                  </MenuItem>
                ))}
            </Select>
          )}
          <br />
          <br />
          <Typography variant="h4">Brands:</Typography>
          <br />
          <InputLabel htmlFor="select-multiple-chip">
            Select Product Brands
          </InputLabel>
          {product && (
            <Select
              multiple
              value={product.brands || []}
              displayEmpty // Allows you to show a custom empty chip
              onChange={handleSelectGroups}
              name="brands"
              input={<Input id="select-multiple-chip" />}
              renderValue={selected => (
                <div className={classes.chips}>
                  {selected.length > 0 && brands ? (
                    selected.map(val => {
                      const bran = containsBrand(brands, val);
                      return (
                        <Chip
                          key={bran.name}
                          label={bran.name}
                          className={classes.chip}
                        />
                      );
                    })
                  ) : (
                      <Chip
                        key={"No Selected Brands!!"}
                        label={"No Selected Brands!!"}
                        className={classes.chipRed}
                      />
                    )}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {brands &&
                brands.length > 0 &&
                brands.map(brand => (
                  <MenuItem
                    key={brand.name || "Error"}
                    value={brand.id || "Error"}
                    style={getStyles(brand.id, product.brands || [], theme)}
                  >
                    {brand.name || "Error"}
                  </MenuItem>
                ))}
            </Select>
          )}
          <br />
          <br />
          <Typography variant="h4">Vendor:</Typography>
          <br />
          <InputLabel htmlFor="select-multiple-chip">Select Vendor</InputLabel>
          {product && vendors && (
            <Select
              multiple
              value={convertObjToArr(product.vendor)}
              onChange={handleSelectVendors}
              name="vendor"
              displayEmpty
              input={<Input id="select-multiple-chip" />}
              renderValue={selected => (
                <div className={classes.chips}>
                  {selected && selected.length > 0 && vendors ? (
                    selected.map(val => {
                      const vendr = containsVendor(vendors, val);
                      return (
                        <Chip
                          key={vendr.name}
                          label={vendr.name}
                          className={classes.chip}
                        />
                      );
                    })
                  ) : (
                      <Chip
                        key={"No Selected Vendors!!"}
                        label={"No Selected Vendors!!"}
                        className={classes.chipRed}
                      />
                    )}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {vendors &&
                vendors.length > 0 &&
                vendors.map(vendor => (
                  <MenuItem
                    key={vendor.name || "Error"}
                    value={vendor.id || "Error"} // set the vendor id as the value, IMPORTANT
                    style={getStyles(
                      vendor.id,
                      convertObjToArr(product.vendor),
                      theme
                    )}
                  >
                    {vendor.name || "Error"}
                  </MenuItem>
                ))}
            </Select>
          )}
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

ProductContent.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object,
  handleProductUpdate: PropTypes.func.isRequired
};

export default ProductContent;
