import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles/*, useTheme*/ } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Checkbox,
  TextField,
  FormControlLabel,
  Grid,
  CircularProgress
} from "@material-ui/core";
import { cleanText } from "helpers";

const useStyles = makeStyles(theme => ({
  root: { marginBottom: 15 },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%"
  },
  attributes: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 150
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2,
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText
  },
  chipRed: {
    backgroundColor: theme.palette.error.light,
    margin: 2,
    color: "white"
  },
  loading: {
    height: "100%",
    marginTop: 200,
    width: "100%",
    alignContent: "center",
    textAlign: "center"
  }
}));

const HeroContent = props => {
  const classes = useStyles();

  const {
    hero,
    categories,
    vendors,
    brands,
    handleheroUpdate,
    className,
    ...rest
  } = props;
  // const theme = useTheme();

  const [localHero, setLocalHero] = useState(
    hero.buttonVisible ? { ...hero, buttonVisible: true } : { ...hero }
  );
  const [loading, setLoading] = useState(false);

  const validateButtonsVisibility = () => {
    if (localHero.buttonVisible) {
      return localHero.buttonText && localHero.buttonURL ? true : false;
    } else {
      return true;
    }
  };

  const handleInputValue = name => event => {
    let value = event.target.value;

    if (name === "description") value = cleanText(value) || "";
    setLocalHero({ ...localHero, [name]: value });
  };

  const handleUpdateHero = async () => {
    setLoading(true);
    const isUpdateValid = validateButtonsVisibility();
    if (isUpdateValid) {
      delete localHero.updatedOn;

      handleheroUpdate && (await handleheroUpdate({ ...localHero }));

      setTimeout(() => {
        setLoading(false);
      }, 800);

      return null;
    }
    setLoading(false);
    alert("Button Text and Button URL are required");
  };

  const handleCheckboxStatus = () => {
    setLocalHero({ ...localHero, buttonVisible: !localHero.buttonVisible });
  };

  if (loading) {
    return (
      <div className={classes.loading}>
        {" "}
        <CircularProgress />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardContent>
          <Typography variant="h4">Hero Title:</Typography>
          <TextField
            required
            id="title-standard-required"
            label="Hero Main Title"
            error={!localHero.title || localHero.title === "New hero"}
            name="title"
            defaultValue={localHero.title || ""}
            onChange={handleInputValue("title")}
            className={classes.textField}
            margin="normal"
          />
          <br />
          <br />
          <Typography variant="h4">Hero Description:</Typography>
          <TextField
            required
            multiline
            id="description-standard-required"
            label="Hero Description"
            error={
              !localHero.description || localHero.description === "New hero"
            }
            name="description"
            defaultValue={localHero.description || ""}
            onChange={handleInputValue("description")}
            className={classes.textField}
            margin="normal"
          />
          <br />
          <br />
          <Typography variant="h4">Button Text:</Typography>
          <TextField
            required
            multiline
            id="buttonText-standard-required"
            label="Button Text"
            error={!hero.buttonText || hero.buttonText === "New hero"}
            name="buttonText"
            defaultValue={hero.buttonText || ""}
            onChange={handleInputValue("buttonText")}
            className={classes.textField}
            margin="normal"
          />
          <br />
          <br />
          <Typography variant="h4">Button URL:</Typography>
          <TextField
            required
            multiline
            id="buttonURL-standard-required"
            label="Button URL"
            error={!hero.buttonURL || hero.buttonURL === "New hero"}
            name="buttonURL"
            defaultValue={hero.buttonURL || ""}
            onChange={handleInputValue("buttonURL")}
            className={classes.textField}
            margin="normal"
          />
          <br />
          <br />
          <Typography variant="h4">Button Visibility:</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={localHero.buttonVisible}
                onClick={handleCheckboxStatus}
              />
            }
            label={localHero.buttonVisible ? "Activated" : "Disabled"}
          />
          <br />
          <br />
          <Grid container justify="flex-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={handleUpdateHero}
            >
              Update Hero Slide
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

HeroContent.propTypes = {
  className: PropTypes.string,
  hero: PropTypes.object,
  handleheroUpdate: PropTypes.func.isRequired
};

export default HeroContent;
