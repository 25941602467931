import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Card, CardContent, Typography, TextField } from "@material-ui/core";
import { numbersOnly } from 'helpers'

const useStyles = makeStyles(() => ({
  root: { marginBottom: 15 },
}));

const FAQPosition = (props) => {
  const { position, handleFAQUpdate, className, ...rest } = props;

  const classes = useStyles();

  const onChangePrice = (field, value) => {
    const re = /^[0-9\b.]+$/;

    // if value is not blank, then test the regex
    if (value === "" || re.test(value)) {
      handleFAQUpdate && handleFAQUpdate({ [field]: value || "" });
    }
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Typography variant="h4">FAQ Position:</Typography>
        <TextField
          required
          id="faqPosition-standard-required"
          label="FAQ Position"
          type="number"
          error={!position || !numbersOnly(position)}
          name="faqPosition"
          value={position}
          onChange={(event) =>
            onChangePrice("position", event.target.value || 0)
          }
          className={classes.textField}
          margin="normal"
        />
      </CardContent>
    </Card>
  );
};

FAQPosition.propTypes = {
  published: PropTypes.bool,
  className: PropTypes.string,
  handleFAQUpdate: PropTypes.func.isRequired,
};

export default FAQPosition;
