import React, { useEffect, useContext, useState } from 'react';
import { makeStyles } from "@material-ui/styles";
import { CircularProgress } from "@material-ui/core";

import PropTypes from 'prop-types';

import { useAuthState } from 'react-firebase-hooks/auth';
import 'firebase/auth';

import { userRoles } from 'common'

// Import Contexts
import { FirebaseContext } from '../../data/Firebase'
import { UserProvider } from "components/Session/UserContext"

const useStyles = makeStyles(theme => ({
  loading: {
    height: "100vh",
    width: "100vw",
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center'
  },
}));


const AuthGuard = props => {
  const { children, ...rest } = props;
  const classes = useStyles()
  const firebase = useContext(FirebaseContext)
  const [user, authLoading/*, authError*/] = useAuthState(firebase.auth());
  const [userObj, setUserObj] = useState(null);
  const [userLoaded, setUserLoaded] = useState(false)

  // Observer only needs ran once, if no user found, user context is null and unauthed
  useEffect(() => {
    const getAuthUser = () => firebase.auth().onAuthStateChanged(async authUser => {
      if (authUser && !authUser.isAnonymous && !userObj) {
        const res = await firebase.firestore().collection("customers").doc(authUser.uid).get()
        
        //Only add the user if it is allowed
        const userData = res.data()
        if(userData.role && userRoles[userData.role]){ // userRoles[userData.role] checks the current dictionary of roles if they exist
          setUserObj({ id: res.id, ...userData });
        }  
        !userLoaded && setUserLoaded(true)
      } else {
        userObj && setUserObj(null); // Only set if not null, avoids unecessary rerender
        !userLoaded && setUserLoaded(true)
      }
    });
    return getAuthUser()
  }, [props]);

  // Show loader if loading user status, routes will never show until this happens
  if (authLoading || !userLoaded) {
    return (
      <div className={classes.loading}>
        <CircularProgress size='5rem' />
      </div>
    );
  }
// Return children with <Routes/> rather than clone everytime and use memory
  return (
    <UserProvider value={userObj}>
      {children}
      {/* {React.cloneElement(children, { ...rest })} */}
    </UserProvider>
  )
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
