import React, { useState, useEffect } from "react";
import { query } from "../../modules/helperFunctions";
import { Grid, Typography/*, CircularProgress*/ } from "@material-ui/core";
import { HorizontalBar } from "react-chartjs-2";
import moment from "moment";

const initialState = {
  labels: [],
  datasets: [
    {
      label: "CTR number",
      backgroundColor: "rgba(255,99,132,0.2)",
      borderColor: "rgba(255,99,132,1)",
      borderWidth: 1,
      hoverBackgroundColor: "rgba(255,99,132,0.4)",
      hoverBorderColor: "rgba(255,99,132,1)",
      data: [],
    },
  ],
};

const CTR = () => {
  const [data, setData] = useState(initialState);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.gapi.analytics.ready(function () {
      var data = query({
        ids: "ga:97083810",
        dimensions: "ga:date",
        metrics:
          "ga:adClicks, ga:impressions, ga:adCost, ga:CTR, ga:CPC, ga:costPerTransaction, ga:transactions, ga:transactionsPerSession, ga:pageviews, ga:timeOnPage",
        "start-date": "30daysAgo",
        "end-date": "yesterday",
      });

      Promise.all([data]).then((results) => {
        // console.log("test.resultsCTR", results);
        var data = initialState;

        results[0].rows.forEach((row) => {
          var label = moment(row[0], "YYYYMMDD").format("Do");

          data.labels.push(label);
          data.datasets[0].data.push(row[4]);
        });
        setLoading(false);
        setData(data);
      });
    });
  }, []);

  if (loading) {
    return (
      <Grid item xs={12} style={{ padding: "1.2rem" }} align="center">
        {/* <CircularProgress /> */}
      </Grid>
    );
  }
  return (
    <Grid item xs={12} style={{ padding: "1.2rem" }}>
      <Typography align="center" variant="h3">
        CTR
      </Typography>
      <HorizontalBar data={data} height={75} />
    </Grid>
  );
};

export default CTR;
